import React from 'react';
import './Perfil.css';
import PageLayout from '../../components/layout/PageLayout';
import {
    Form,
    Input,
    Tooltip,
    Button,
    Divider,
    Icon,
} from 'antd';
import history from '../../history';
import { currentUser, authenticate } from '../../components/auth/auth-provider';
import { fireBase } from '../../firebase/firebase';
import { validateUser, uploadAvatar, updateProfile, updatePassword } from './Funcs-Perfil';
import mensagem from '../../components/messages/message';
import PasswordStrengthBar from 'react-password-strength-bar';


class Perfil extends React.Component {

    state = {
        bloqueiaBotaoSalvar: false,
        photoURL: '',
        loading: false,
        loadingButton: false,
        data: {
            nome: '',
            oldPassword: '',
            passwordStrength: '',
            confirmPassword: '',
            score: undefined
        },
        edited: false,
        pictures: [],
        validateStatus: ''
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.btnUploadClick = this.btnUploadClick.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.oldPasswordChanged = this.oldPasswordChanged.bind(this);
        this.scoreCheck = this.scoreCheck.bind(this);
        this.confirmChanged = this.confirmChanged.bind(this);
        this.estadoBotao = this.estadoBotao.bind(this);
    }

    componentDidMount() {
        const user = currentUser();
        this.setState({ data: { nome: user.displayName }, photoURL: user.photoURL })
    }

    cancelClick() {
        history.push('/dashboard');
        history.go();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (err) {
                return;
            }

            if (!values.nome) {
                mensagem.openNotificationWithIcon('error', 'Cadastro incompleto', 'Informe o seu nome');
                return;
            }

            if (values.newPassword && values.newPassword.length < 6) {
                mensagem.openNotificationWithIcon('error', 'Senha inválida', 'Sua nova senha precisa ter no mínimo 6 caracteres');
                return;
            }

            if ((!this.state.edited) &&
                (values.nome === this.state.data.nome) &&
                (!values.newPassword)) {
                mensagem.openNotificationWithIcon('warning', 'Aviso', 'Nenhuma alteração a ser salva');
                return;
            }

            this.setState({ loadingButton: true });
            let isOk = await validateUser(values.password);
            if (!isOk) {
                mensagem.openNotificationWithIcon('error', 'Senha incorreta', 'Para alterar o seu cadastro, você precisa informar corretamente qual é a sua senha');
                this.setState({ loadingButton: false });
                return;
            }

            let imagePath = this.state.photoURL;
            if (this.state.edited) {
                const res = await uploadAvatar(this.state.photoURL);
                console.log(res);
                if (res.success) {
                    imagePath = res.url;
                }
                else {
                    mensagem.openNotificationWithIcon('error', 'Erro', 'Erro ao tentar salvar sua foto de perfil. Tente novamente');
                    this.setState({ loadingButton: false });
                    return;
                }
            }
            const user = fireBase.auth().currentUser;
            if (user.displayName !== values.nome || user.photoURL !== imagePath) {
                // Atualizo o perfil do usuário
                isOk = await updateProfile(values.nome, imagePath);
                if (!isOk) {
                    mensagem.openNotificationWithIcon('error', 'Erro', 'Erro ao salvar seus dados de perfil. Tente novamente');
                    this.setState({ loadingButton: false });
                    return;
                }
            }

            if (values.newPassword) {
                isOk = await updatePassword(values.newPassword);
                if (!isOk) {
                    mensagem.openNotificationWithIcon('error', 'Erro', 'Erro ao alterar sua senha. Tente novamente');
                    this.setState({ loadingButton: false });
                    return;
                }
            }

            if (isOk) {
                const nuser = fireBase.auth().currentUser;
                authenticate(nuser);
                mensagem.openNotificationWithIcon('success', 'Perfil salvo com sucesso');
                setTimeout(() => {
                    history.push('/dashboard');
                    history.go();
                }, 1500);
            }
        });
    }

    btnUploadClick() {
        this.refs.myFile.click();
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleChange(e) {
        this.getBase64(e.target.files[0], photoURL => {
            this.setState({
                photoURL,
                loading: false,
                edited: true,
            })
        }
        );
    }

    onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }

    oldPasswordChanged(obj) {
        const data = this.state.data;
        data.oldPassword = obj.target.value;
        this.setState({ data });
        this.estadoBotao();
    }

    passwordChanged(obj) {
        const data = this.state.data;
        data.passwordStrength = obj.target.value;
        this.setState({ data });
        this.estadoBotao();
    }

    confirmChanged(obj) {
        const data = this.state.data;
        data.confirmPassword = obj.target.value;
        this.setState({ data });
        this.estadoBotao();
    }

    scoreCheck(score) {
        const data = this.state.data;
        data.score = score;
        this.setState({ data })
    }

    estadoBotao() {
        const password = this.state.data.oldPassword;
        const newPassword = this.state.data.passwordStrength;
        const confirmPassword = this.state.data.confirmPassword;
        const score = this.state.data.score;
        let botao = false;

        this.setState({ help: this.props.form.message, validateStatus: 'success' });
        if ((newPassword && score <= 1) || score >= 2) {
            botao = true;
            if (password && password !== newPassword && newPassword === confirmPassword) botao = false;
            else if (newPassword !== confirmPassword || (newPassword === password && password)) {
                this.setState({ help: 'Senhas não conferem', validateStatus: 'error' });
                if (newPassword === password && password) {
                    this.setState({ help: 'Nova senha não pode ser igual a atual', validateStatus: 'error' });
                }
            }
        }
        this.setState({ bloqueiaBotaoSalvar: botao })

    }

    render() {

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const { photoURL } = this.state;

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Aguardando Foto</div>
            </div>
        );

        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <PageLayout selectItem={'perfil'}>

                    <div className="div-form-profile">

                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item>
                                <div className="clearfix">
                                    <input
                                        className="uploadComponent"
                                        type="file"
                                        name="myFile"
                                        ref="myFile"
                                        onChange={this.handleChange}
                                    />
                                    {photoURL ? <img src={photoURL} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    <Button onClick={this.btnUploadClick}>Selecione sua foto</Button>

                                </div>
                            </Form.Item>

                            <Form.Item
                                label={
                                    <span>
                                        Nome&nbsp;
                                        <Tooltip title="Qual é o seu nome?">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                {getFieldDecorator('nome', {
                                    initialValue: this.state.data.nome,
                                    rules: [{ required: true, message: 'Informe o seu nome', whitespace: true }],
                                })(<Input maxLength={50} />)}
                            </Form.Item>


                            <Form.Item
                                label={
                                    <span>
                                        Senha&nbsp;
                                        <Tooltip title="Qual é a sua senha atual?">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Informe a sua senha atual', whitespace: true }],
                                })(<Input.Password onChange={this.oldPasswordChanged} style={{ width: '95%' }} />)}
                            </Form.Item>

                            <Form.Item label="Nova Senha">
                                {getFieldDecorator('newPassword', {
                                    rules: [{ required: false, message: 'Informe uma nova senha' }],
                                })(<Input.Password onChange={this.passwordChanged} style={{ width: '95%' }} />)}

                                <PasswordStrengthBar
                                    className='passwordStrength'
                                    password={this.state.data.passwordStrength}
                                    scoreWords={['Senha muito fraca', 'Senha fraca', 'Senha razoável', 'Senha boa', 'Senha forte']}
                                    shortScoreWord={this.state.data.passwordStrength ? 'Senha muito curta' : ''}
                                    minLength={6}
                                    onChangeScore={(score) => this.scoreCheck(score)}
                                    style={{ width: '112%' }} />
                            </Form.Item>


                            <Form.Item label="Confirme"
                                help={this.state.help}
                                validateStatus={this.state.validateStatus}>

                                {getFieldDecorator('confirmNewPassword', {

                                    rules: [{ required: false, message: 'Confirme sua nova Senha' }],
                                })(<Input.Password onChange={this.confirmChanged} style={{ width: '95%' }} />)}
                            </Form.Item>

                            <Divider></Divider>


                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" loading={this.state.loadingButton} disabled={this.state.bloqueiaBotaoSalvar}>
                                    Salvar
                                </Button>
                                <Divider type="vertical" />
                                <Button onClick={this.cancelClick}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </PageLayout>
            </div>
        );
    }
}

export default Form.create()(Perfil);
