import {
  Component
} from 'react';
import {
  ROUTER_API,
  ROUTER_API_BNF,
  ROUTER_API_LOYALTY
} from '../../firebase/firebase';


async function _getToken(key) {

  return new Promise((resolve, reject) => {
    const url = `${ROUTER_API}token/convenio/byid/get/${key}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.code === 200) {
          resolve(responseData);
        } else {
          reject(responseData);
        }
      })
      .catch(err => {
        console.log(err);
        reject({});
      })
  });
}

export default class API extends Component {

  async getToken(convenioId) {
    const token = await _getToken(convenioId);
    return token;
  }

  getPoints(userCPForCelular, findByCelular, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_LOYALTY + `get/${userCPForCelular}`;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
          'celular': findByCelular,
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  savePoints(person, valueInCents, nrDocumento, findByCelular, operador, token) {
    return new Promise((resolve, reject) => {

      if (person.cpf) {
        person.cpf = person.cpf.replace(/[^\d]+/g, '');
      }

      const endpoint = person.cpf !== '' ? person.cpf : person.endereco.telefone;
      const url = ROUTER_API_LOYALTY + `save/${endpoint}`;
      const beneficiario = `${person.nome} ${person.sobrenome}`;

      const body = JSON.stringify({
        valueInCents,
        nrDocumento,
        operador,
        beneficiario
      });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
          'celular': findByCelular,
        },
        body,
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 201) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  rescuePoints(person, score, findByCelular, token) {
    return new Promise((resolve, reject) => {
      if (person.cpf) {
        person.cpf = person.cpf.replace(/[^\d]+/g, '');
      }

      const endpoint = person.cpf !== '' ? person.cpf : person.endereco.telefone;
      const url = ROUTER_API_LOYALTY + `rescue/${endpoint}`;

      const body = JSON.stringify({
        score
      });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
          'celular': findByCelular,
        },
        body,
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  refund(pontosId, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_LOYALTY + `reversal/${pontosId}`;

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
        },
      }).then(response => response.json())
        .then(responseData => {
          if (responseData.code === 201) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  refundRescue(voucherId, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_LOYALTY + `rescue/reversal/${voucherId}`;

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
        },
      }).then(response => response.json())
        .then(responseData => {
          if (responseData.code === 201) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  sendEmailToken(email) {
    // A rotina que será chamada retornará o token

    return new Promise((resolve, reject) => {
      const url = ROUTER_API_BNF + `sendEmailToken/${email}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
      });
  }

  validateToken(email, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_BNF + `validateToken/${email}/${token}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  getPointsCustom(beneficiarioId, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_LOYALTY + `custom/${beneficiarioId}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData.pontos);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  getConvenioById(convenioId, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_LOYALTY + `convenio/${convenioId}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData.convenio);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });

  }
    

  savePerson(person, findByCelular, token) {
    return new Promise((resolve, reject) => {

      if (person.cpf) {
        person.cpf = person.cpf.replace(/[^\d]+/g, '');
      }

      const endpoint = person.cpf !== '' ? person.cpf : person.phoneNumber;
      const url = ROUTER_API_BNF + `post/${endpoint}`;

      const body = JSON.stringify(person);

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
          'celular': findByCelular,
        },
        body,
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 201) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  getPersonByCPF(cpf, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_BNF + `get/${cpf}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });    
  }

  sendReceipt(comprovante, contact, sendByEmail, token) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API_LOYALTY + `sendReceipt/${contact}`;

      const body = JSON.stringify({
        comprovante,
        sendByEmail
      });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`
        },
        body,
      }).catch(err => {
        console.log(err);
        reject({});
      });
    });
  }

  authUser(login, password) {
    return new Promise((resolve, reject) => {
      const url = ROUTER_API + `authentication/fidelidade`;

      const body = JSON.stringify({
        login,
        password
      });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
    });
  }
}