import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


class AdministradorUsuarioDB extends DataManager {

    state = {
        collection: 'administradoresUsuarios',
        orderBy: ['nome', 'sobrenome'], // usado na classe pai
    }

    async getByOwnerIdCustom(gestorId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByOwnerIdAndAdminId(gestorId, administradorId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .where('administrador.id', '==', administradorId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCPF(cpf) {
        const data = await this.getByParamValue('cpf', cpf);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }
}

const administradorUsuarioDB = new AdministradorUsuarioDB();
export default administradorUsuarioDB;