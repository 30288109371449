import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


async function queryBy10(ownerId, ids, dataIni, dataFim, fromCache) {
    const query = fbDatabase
        .collection('pontos')
        .where('ownerId', '==', ownerId)
        .where('data', '>=', dataIni)
        .where('data', '<=', dataFim)
        .where('convenio.id', 'in', ids);

    const snapshot = await new DataManager().getQueryData(query, fromCache);

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
    }));

    return data;
}

function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

class PontosDB extends DataManager {

    state = {
        collection: 'pontos'
    }

    async getByPeriodo(dataIni, dataFinal, ownerId, conveniosIds, fromCache) {
        if (conveniosIds && conveniosIds.constructor !== Array) {
            const conv = []
            conv.push({ key: conveniosIds });
            conveniosIds = conv;
        }

        let pontos = [];
        if (!conveniosIds || conveniosIds.length === 0) {
            let query = fbDatabase
                .collection(this.state.collection)
                .where('data', '>=', dataIni)
                .where('data', '<=', dataFinal)

            if (ownerId) {
                query += query.where('ownerId', '==', ownerId)
            }

            const snapshot = await this.getQueryData(query, fromCache);

            const data = snapshot.docs.map((item) => ({
                key: item.id,
                ...item.data(),
            }));

            pontos.push.apply(pontos, data);
            return pontos;
        }

        // Quando houver convenios ids informados na lista

        let ids = [];
        if (!conveniosIds) {
            conveniosIds = []
        }

        for (let index = 0; index < conveniosIds.length; index++) {
            ids.push(conveniosIds[index].key);
            if (ids.length >= 10) {
                const data = await queryBy10(ownerId, ids, dataIni, dataFinal, fromCache);
                pontos.push.apply(pontos, data);
                ids = [];
            }
        }

        if (ids.length > 0) {
            const data = await queryBy10(ownerId, ids, dataIni, dataFinal, fromCache);
            pontos.push.apply(pontos, data);
        }

        return pontos;
    }

    async getByParticipante(beneficiarioId, convenioId, exibirPontosResgatados, dataIni, dataFim) {
        let pontos = [];

        let query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .where('convenio.id', '==', convenioId);

        if (!exibirPontosResgatados) {
            query = query.where('resgatou', '==', false);
        }

        if (dataIni && dataFim) {
            query = query.where('data', '>=', dataIni);
            query = query.where('data', '<=', dataFim);
        }

        query = query.orderBy('data', 'desc');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        pontos.push.apply(pontos, data);

        return pontos;
    }

    async getByConvenio(convenioId, exibirPontosResgatados, dataIni, dataFim) {
        let pontos = [];

        let query = fbDatabase
            .collection(this.state.collection)
            .where('convenio.id', '==', convenioId);

        if (!exibirPontosResgatados) {
            query = query.where('resgatou', '==', false);
        }

        if (dataIni && dataFim) {
            query = query.where('data', '>=', dataIni);
            query = query.where('data', '<=', dataFim);
        }

        query = query.orderBy('data', 'desc');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        pontos.push.apply(pontos, data);

        return pontos;
    }

    async getLastRegisterByParticipante(beneficiarioId, fromCache) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .orderBy('data', 'desc')
            .limit(1);

        const snapshot = await this.getQueryData(query, fromCache, '/getLastRegisterByParticipante');

        if (snapshot.empty) {
            return null;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        return data[0];
    }

    async getFirstRegisterByParticipante(beneficiarioId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .orderBy('data', 'asc')
            .limit(1);

        const snapshot = await this.getQueryData(query, true, '/getFirstRegisterByParticipante');

        if (snapshot.empty) {
            return null;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        return data[0];
    }

    async getPoints(beneficiarioId, administradorId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .where('administrador.id', '==', administradorId)
            .where('resgatou', '==', false)
            .orderBy('data', 'asc');

        const snapshot = await this.getQueryData(query, true, '/getPoints');

        let score = 0;
        const now = new Date();

        for (let index = 0; index < snapshot.docs.length; index++) {
            const {
                expiraEm,
                estornou,
                pontos,
                baixados
            } = snapshot.docs[index].data();

            if (expiraEm !== undefined && expiraEm.toDate() <= now) {
                continue
            }

            if (estornou === undefined) {
                score += (pontos - baixados);
            }
        }

        score = round(score, 2);

        return score;
    }

    async getExpiredPoints(beneficiarioId, administradorId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .where('administrador.id', '==', administradorId)
            .where('resgatou', '==', false)
            .where('expiraEm', '<=', new Date());

        const snapshot = await this.getQueryData(query, true, '/getExpiredPoints');

        let expiredPoints = 0;
        for (let index = 0; index < snapshot.docs.length; index++) {
            const {
                estornou,
                pontos,
                baixados
            } = snapshot.docs[index].data();

            if (estornou === undefined) {
                expiredPoints += (pontos - baixados);
            }
        }

        expiredPoints = round(expiredPoints, 2);

        return expiredPoints;
    }

    async getPointsCustom(beneficiarioId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .where('resgatou', '==', false)
            .orderBy('data', 'asc');

        const snapshot = await this.getQueryData(query, false);

        if (snapshot.empty) {
            return null;
        }

        const now = new Date();
        const pontosObj = {};


        for (let i = 0; i < snapshot.docs.length; i++) {
            const {
                expiraEm,
                estornou,
                administrador,
                convenio,
                pontos,
                baixados
            } = snapshot.docs[i].data();

            if (expiraEm !== undefined && expiraEm.toDate() <= now) {
                continue;
            }

            if (!pontosObj[administrador.id]) {
                pontosObj[administrador.id] = {
                    key: snapshot.docs[i].id,
                    convenio: convenio.nome,
                    convenioId: convenio.id,
                    saldo: 0
                };
            }

            if (estornou === undefined) {
                pontosObj[administrador.id].saldo += (pontos - baixados);
            }
        }

        const pontos = [];
        for (const key in pontosObj) {
            pontosObj[key].saldo = round(pontosObj[key].saldo, 2);
            pontos.push(pontosObj[key]);
        }

        return pontos;
    }
}

const pontoDB = new PontosDB();
export default pontoDB;