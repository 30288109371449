import DataManager from './dtm';
import { fbDatabase, fbFirestore } from '../firebase/firebase';


class BeneficiarioDB extends DataManager {

    state = {
        collection: 'beneficiarios',
        orderBy: ['nome', 'sobrenome'], // usado na classe pai
    }

    async getByOwnerIdCustom(gestorId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByConvenioIdAndPeriodo(convenioId, dataIni, dataFim, fromCache) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('convenio.id', '==', convenioId)
            .where('cadastro', '>=', dataIni)
            .where('cadastro', '<=', dataFim)
            .orderBy('cadastro');

        const snapshot = await this.getQueryData(query, fromCache);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCPF(cpf) {
        const data = await this.getByParamValue('cpf', cpf);

        if (!data) {
            return undefined;
        }

        return data;
    }

    async getOneByCPFAndPhone(cpf, phone) {
        const { collection } = this.state;

        const query = fbDatabase
            .collection(collection)
            .where('cpf', '==', cpf)
            .where('endereco.telefone', '==', phone)
            .limit(1);

        const snapshot = await this.getQueryData(query, false);

        if (snapshot.empty) {
            return undefined;
        }

        const beneficiario = {
            id: snapshot.docs[0].id,
            ...snapshot.docs[0].data()
        };

        return beneficiario;
    }

    async getAllByCPFAndPhone(cpf, phone) {
        const { collection } = this.state;

        const query = fbDatabase
            .collection(collection)
            .where('cpf', '==', cpf)
            .where('endereco.telefone', '==', phone);

        const snapshot = await this.getQueryData(query, false);

        if (snapshot.empty) {
            return undefined;
        }

        const beneficiarios = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        return beneficiarios;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }

    async getAtivosByConvenioId(ownerId, convenioId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('convenio.id', '==', convenioId)
            .where('ativo', '==', true)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getAtivosByConvenioIdLimited(ownerId, convenioId, limit, lastDocId) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('convenio.id', '==', convenioId)
            .where('ativo', '==', true)
            .orderBy('nome')
            .orderBy('sobrenome')

        if (lastDocId) {
            const doc = await fbDatabase
                .collection(this.state.collection)
                .doc(lastDocId)
                .get();

            query = query.startAfter(doc);
        }

        query = query.limit(limit);

        const snapshot = await this.getQueryData(query, true, '/getAtivosByConvenioIdLimited');

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getAtivosByConvenioIdAndCreditoAtivo(ownerId, convenioId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('convenio.id', '==', convenioId)
            .where('credito.ativo', '==', true)
            .where('ativo', '==', true)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }


    async getByOwnerIdAndAdminId(ownerId, administradorId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('administrador.id', '==', administradorId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCPFAndOwnerId(cpf, ownerId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('cpf', '==', cpf)
            .where('ownerId', '==', ownerId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data[0];
    }

    async getByEmailAndOwnerId(email, ownerId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('email', '==', email)
            .where('ownerId', '==', ownerId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data[0];
    }

    async getByPhoneNumberAndOwnerId(telefone, ownerId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('endereco.telefone', '==', telefone)
            .where('ownerId', '==', ownerId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data[0];
    }

    async getByPalavraChaveLimited(ownerId, convenioId, words, alreadyFound, limit) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('convenio.id', '==', convenioId)

        for (let i of words) {
            const keyword = 'keywords.' + i;
            query = query.where(keyword, '==', true);
        }

        if (alreadyFound.length > 0) {
            query = query
                .where(
                    fbFirestore.FieldPath.documentId(),
                    'not-in',
                    alreadyFound
                );
        }

        query = query.limit(limit);

        const snapshot = await this.getQueryData(query, true, '/getByPalavraChaveLimited');

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async getByPalavraChaveAndOwnerId(words, ownerId) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)

        for (let i of words) {
            const keyword = 'keywords.' + i.toLowerCase();
            query = query.where(keyword, '==', true);
        }

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async getByLetterLimited(ownerId, convenioId, letter, limit, lastDocId) {
        const end = letter.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('convenio.id', '==', convenioId)
            .where('nome', '>=', letter)
            .where('nome', '<', end)

        if (lastDocId) {
            const doc = await fbDatabase
                .collection(this.state.collection)
                .doc(lastDocId)
                .get();

            query = query.startAfter(doc);
        }

        query = query.limit(limit);

        const snapshot = await this.getQueryData(query, true, '/getByLetterLimited');

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

}

const beneficiarioDB = new BeneficiarioDB();
export default beneficiarioDB;