import { Component } from 'react';
import { Modal, notification } from 'antd';
const { warning } = Modal;
const { confirm } = Modal;

class Message extends Component {

    async avisar(message) {
        return new Promise((resolve, reject) => {
            warning({
                title: 'Atenção',
                content: message,
                async onOk() {
                    resolve(true);
                },
            });
        }).catch((error) => {
            console.log('Oops errors!');
            return false;
        });
    }

    async confirmar(message) {
        return new Promise((resolve, reject) => {
            confirm({
                title: 'Confirmação',
                content: message,
                okText: 'Sim',
                okType: 'danger',
                cancelText: 'Não',
                async onOk() {
                    resolve(true);
                },
                onCancel() {
                    reject(false);
                }
            });
        }).catch((error) => {
            console.log('Oops errors!');
            return false;
        });
    }

    openNotificationWithIcon = (type, title, description, duration) => {
        if (!duration) {
            duration = 4; // this is the default time
        }
        notification[type]({
            message: [title],
            description: [description],
            duration,
        });
    };
}

const mensagem = new Message();
export default mensagem