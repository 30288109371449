import React from 'react';
import { withRouter } from 'react-router'
import { Button, message, Modal, Steps, Table, Card, Checkbox, DatePicker, Result } from 'antd';
import { getConvenio } from '../../../components/auth/auth-provider';
import { getColor } from '../../../components/layout/themes/LayoutCSS';
import locale from 'antd/es/date-picker/locale/pt_BR';
import PageLayout from '../../../components/layout/PageLayout';
import CPFInput from '../../../components/masks/cpf';
import TelefoneInput from '../../../components/masks/telefone';
import API from '../../../components/api/api';
import pontoDB from '../../../dataManager/dtmPontos';
import '../Estornar.css'


const { Step } = Steps;
const { RangePicker } = DatePicker;

const api = new API();
const dateFormat = 'DD/MM/YYYY';
let token = undefined;
let objectCounter = undefined;

class EstornarPontos extends React.Component {
    state = {
        step: 0,
        showModal: false,
        loadingNext: false,
        loadingBack: false,
        loadingTable: false,
        loadingFilter: false,
        loadingModal: false,
        countDownStr: 'Finalizar (15 seg)',
        tableData: [],
        periodo: {
            inicial: undefined,
            final: undefined,
        },
        pontuacao: {
            key: '',
            data: '',
            hora: '',
            pontos: 0,
        },
        beneficiario: {
            nome: '',
            id: '',
            cpf: '',
            telefone: ''
        },
        result: {
            title: '',
            type: ''
        }
    }

    constructor(props) {
        super(props);
        this.back = this.back.bind(this);
        this.finish = this.finish.bind(this);
        this.startCounter = this.startCounter.bind(this);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onCpfChange = this.onCpfChange.bind(this);
        this.onTelefoneChange = this.onTelefoneChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);

        this.stepOne = this.stepOne.bind(this);
        this.showModal = this.showModal.bind(this);

        this.loadTable = this.loadTable.bind(this);
        this.selecionarPontuacao = this.selecionarPontuacao.bind(this);
        this.estornar = this.estornar.bind(this);
    }

    back() {
        this.setState({ loadingBack: true })
        setTimeout(() => {
            this.setState({ loadingBack: false, step: 0 });
        }, 750);
    }

    finish() {
        const periodo = {
            inicial: undefined,
            final: undefined,
        };

        const beneficiario = {
            nome: '',
            id: '',
            cpf: '',
            telefone: ''
        };

        const result = {
            title: '',
            type: ''
        };

        clearInterval(objectCounter);
        this.setState({ periodo, beneficiario, result, step: 0 });
    }

    startCounter() {
        this.setState({ countDownStr: `Finalizar (15 seg)` });
        const a_this = this;
        var counter = 15;
        objectCounter = setInterval(function () {
            counter--;
            a_this.setState({ countDownStr: `Finalizar (${counter} seg)` });
            if (counter <= 0) {
                clearInterval(objectCounter);
                a_this.finish();
            }
        }, 1000);
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            this.next();
        }
    }

    onCpfChange(e) {
        const beneficiario = this.state.beneficiario;
        beneficiario.cpf = e.target.value.replace(/[^\d]+/g, '');
        this.setState({ beneficiario });
    }

    onTelefoneChange(e) {
        const beneficiario = this.state.beneficiario;
        beneficiario.telefone = e.target.value;
        this.setState({ beneficiario });
    }

    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }
        this.setState({ periodo });
    }

    onFilterClick() {
        this.setState({ loadingFilter: true });
        this.loadTable();
        this.setState({ loadingFilter: false });
    }

    async stepOne() {
        this.setState({ loadingNext: true })

        if (!this.state.beneficiario.cpf && !this.state.beneficiario.telefone) {
            message.warning({ content: 'Informe o CPF, CNPJ ou Celular' });
            this.setState({ loadingNext: false });
            return;
        }

        const convenio = getConvenio();
        try {
            const { token: getToken } = await api.getToken(convenio.key);
            token = getToken;
        } catch (err) {
            message.error({ content: err.message ? err.message : err.error });
            this.setState({ loadingNext: false });
            return;
        }

        if (token) {
            const findByCelular =
                this.state.beneficiario.telefone && !this.state.beneficiario.cpf ? true : false;

            const data = findByCelular ? this.state.beneficiario.telefone : this.state.beneficiario.cpf;

            api.getPoints(data, findByCelular, token)
                .then(async res => {
                    const beneficiario = this.state.beneficiario;
                    beneficiario.nome = `${res.participant.firstName} ${res.participant.lastName}`;
                    beneficiario.cpf = res.participant.doc;
                    beneficiario.id = res.participant.id;

                    this.loadTable();
                    this.setState({ step: 1, loadingNext: false, beneficiario });
                })
                .catch(err => {
                    if (!err.userExists && err.userExists !== undefined) {
                        message.error({ content: 'Não existe um Beneficiário com CPF/Celular informado' });
                    } else {
                        console.log(err);
                    }

                    this.setState({ loadingNext: false });
                })
        }
    }

    showModal() {
        this.setState({ loadingNext: true });
        this.setState({ showModal: true, loadingNext: false });
    }

    async loadTable() {
        this.setState({ loadingTable: true });

        const beneficiario = this.state.beneficiario;
        const convenio = getConvenio();

        const { inicial, final } = this.state.periodo;
        const date = new Date();

        const dateI = inicial ? inicial.startOf('day').toDate() : new Date(date.getFullYear(), date.getMonth(), 1);
        const dateF = final ? final.endOf('day').toDate() : date;

        const pontos = await pontoDB.getByParticipante(
            beneficiario.id,
            convenio.key,
            true,
            dateI,
            dateF
        );

        const tableData = pontos.map(item => {
            const { key, pontos, data, dataEstorno,
                expiraEm, resgatou, estornou, baixados } = item;

            return {
                key,
                pontos,
                baixados,
                data: data.toDate().toLocaleString(),
                dataEstorno: dataEstorno ? dataEstorno.toDate().toLocaleString() : '',
                expira: expiraEm.toDate().toLocaleString(),
                expiraData: expiraEm.toDate(),
                resgatou: resgatou ? 'Sim' : 'Não',
                estornou: estornou ? 'Sim' : 'Não',
                disabled: false
            };
        })

        this.setState({ loadingTable: false, tableData });
    }

    async selecionarPontuacao(e, item) {
        this.setState({ loadingTable: true });

        setTimeout(() => {
            const checked = e.target.checked;
            const tableData = this.state.tableData;
            let pontuacao = {};

            if (checked) {
                const data = item.data.split(' ');
                pontuacao.key = item.key;
                pontuacao.pontos = item.pontos;
                pontuacao.data = data[0];
                pontuacao.hora = data[1];

                for (let i = 0; i < tableData.length; i++) {
                    if (tableData[i].key !== item.key) {
                        tableData[i].disabled = true;
                    }
                }
            } else {
                for (let i = 0; i < tableData.length; i++) {
                    tableData[i].disabled = false;
                }
            }

            this.setState({ pontuacao, tableData, loadingTable: false });
        }, 500);
    }

    async estornar() {
        this.setState({ loadingModal: true });

        const { pontuacao } = this.state;
        const refund = await api.refund(pontuacao.key, token);
        let result = {};

        if (refund.code === 201) {
            result = {
                type: 'success',
                title: refund.message
            };
        } else {
            result = {
                type: 'error',
                title: refund.message
            };
        }

        this.startCounter();
        this.setState({ loadingModal: false, showModal: false, step: 2, result });
    }

    render() {
        const COLUMNS_RESGATES = [
            {
                title: 'Data',
                dataIndex: 'data',
                key: 'data',
            },
            {
                title: 'Expira em',
                dataIndex: 'expira',
                key: 'expira'
            },
            {
                title: 'Data do Estorno',
                dataIndex: 'dataEstorno',
                key: 'dataEstorno',
            },
            {
                title: 'Pontos',
                dataIndex: 'pontos',
                key: 'pontos',
            },
            {
                title: 'Pontos Baixados',
                dataIndex: 'baixados',
                key: 'baixados',
            },
            {
                title: 'Resgatou?',
                dataIndex: 'resgatou',
                key: 'resgatou',
            },
            {
                title: 'Estornado?',
                dataIndex: 'estornou',
                key: 'estornou',
            },
            {
                title: '',
                width: '1.25rem',
                key: 'checkbox',
                render: (item) => (
                    <Checkbox
                        disabled={
                            item.resgatou === 'Sim' ||
                                item.estornou === 'Sim' ||
                                item.baixados > 0 ||
                                item.expiraData < new Date() ?
                                true : item.disabled
                        }

                        onChange={(e) => this.selecionarPontuacao(e, item)}
                    />
                ),
            }
        ];

        return (
            <div>
                <PageLayout selectItem={'estornar/pontos'}>
                    <div className='parent'>
                        <div className='containerSteps'>
                            <Steps current={this.state.step} >
                                <Step title='Identificação' />
                                <Step title='Pontos' />
                                <Step title='Finalização' />
                            </Steps>
                        </div>

                        <div className='container'>
                            {this.state.step === 0 && (
                                <div>
                                    <div>
                                        <CPFInput
                                            className='input inputCPF'
                                            value={this.state.beneficiario.cpf}
                                            onChange={this.onCpfChange}
                                            onKeyDown={this.onKeyDown}
                                        />

                                        <p className='txtCelular'>ou Celular</p>

                                        <TelefoneInput
                                            className='input inputTelefone'
                                            value={this.state.beneficiario.telefone}
                                            onChange={this.onTelefoneChange}
                                        />
                                    </div>

                                    <Button
                                        className='button'
                                        type='primary'
                                        loading={this.state.loadingNext}
                                        onClick={() => this.stepOne()}
                                    >
                                        Buscar Pontos
                                    </Button>
                                </div>
                            )}

                            {this.state.step === 1 && (
                                <div>
                                    <Card
                                        className='cardBeneficiario'
                                        title='Beneficiário'
                                        headStyle={{ background: getColor(), boder: 0, color: 'white' }}
                                        style={{ left: '0px', marginBottom: '2rem' }}
                                    >
                                        <div className='labelCardBeneficiario'>
                                            {this.state.beneficiario.nome}
                                        </div>
                                    </Card>

                                    <div className='containerPeriodo'>
                                        <label className='labelPeriodo'>
                                            Selecionar Período:
                                        </label>

                                        <RangePicker
                                            style={{ width: '80%', margin: '0 2rem 2rem' }}
                                            locale={locale}
                                            format={dateFormat}
                                            placeholder={['Data Inicial', 'Data Final']}
                                            onChange={this.onDateChange}
                                            value={[this.state.periodo.inicial, this.state.periodo.final]}
                                        />

                                        <Button
                                            type='primary'
                                            loading={this.state.loadingFilter}
                                            disabled={this.state.periodo.inicial === undefined}
                                            onClick={() => this.onFilterClick()}
                                        >
                                            Filtrar
                                        </Button>
                                    </div>

                                    <Table
                                        columns={COLUMNS_RESGATES}
                                        dataSource={this.state.tableData}
                                        loading={this.state.loadingTable}
                                        rowKey={record => record.key}
                                        pagination={{
                                            defaultPageSize: 10,
                                            position: 'both',
                                            locale: { items_per_page: "" }
                                        }}
                                    />

                                    <div className='textEstornoPontos'>
                                        <p>*Pontos já resgatados ou parcialmente utilizados não podem ser estornados. Nesses casos o resgate precisa ser estornado.</p>
                                    </div>

                                    <Button
                                        className='button'
                                        loading={this.state.loadingBack}
                                        onClick={() => this.back()}
                                        style={{ marginRight: '1rem' }}
                                    >
                                        Voltar
                                    </Button>

                                    <Button
                                        className='button'
                                        type='primary'
                                        loading={this.state.loadingNext}
                                        onClick={() => this.showModal()}
                                    >
                                        Selecionar Pontuação
                                    </Button>
                                </div>
                            )}

                            {this.state.step === 2 && (
                                <Result
                                    status={this.state.result.type}
                                    title={this.state.result.title}
                                    extra={[
                                        <Button
                                            type='primary'
                                            onClick={() => this.finish()}
                                        >
                                            {this.state.countDownStr}
                                        </Button>
                                    ]}
                                />
                            )}

                            <Modal
                                className='modalEstorno'
                                title='Informações dos Pontos'
                                visible={this.state.showModal}
                                closable={false}
                                destroyOnClose={true}
                                okText='Estornar'
                                confirmLoading={this.state.loadingModal}
                                onCancel={() => this.setState({ showModal: false })}
                                onOk={this.estornar}
                            >
                                <p>Beneficiário: <a>{this.state.beneficiario.nome}</a></p>
                                <p>Data da Pontuação: <a>{this.state.pontuacao.data}</a></p>
                                <p>Horário: <a>{this.state.pontuacao.hora}</a></p>
                                <p>Total de Pontos: <a>{this.state.pontuacao.pontos}</a></p>
                            </Modal>
                        </div>
                    </div>
                </PageLayout>
            </div>
        );
    }
}

export default withRouter(EstornarPontos);