import React, { Component } from 'react';
import { withRouter } from 'react-router';

import {
    Table,
    Button,
    message,
    notification,
    Checkbox,
    Tooltip,
    Icon,
    Modal
} from 'antd';

import {
    getBeneficiariosByLetter,
    getBeneficiariosByPalavraChave,
    getBeneficiariosConvenio,
    getPontosExpirados,
    getPontosValidos
} from './BeneficiariosF';

import PageLayout from '../../components/layout/PageLayout';
import listObj from '../../components/listSearch/listSearch';
import Search from 'antd/lib/input/Search';
import mensagem from '../../components/messages/message';
import BeneficiariosCRUD from '../../components/cruds/beneficiario/BeneficiariosCRUD';

import './Beneficiarios.css';


const COLUMNS = [
    {
        title: 'Nome',
        dataIndex: 'nomeCompleto',
        key: 'nomeCompleto'
    },
    {
        title: 'Telefone',
        dataIndex: 'telefone',
        key: 'telefone'
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: 'Data e Hora do Cadastro',
        dataIndex: 'dataCadastro',
        key: 'dataCadastro'
    },
    {
        title: 'Data e Hora da Última Pontuação',
        dataIndex: 'dataUltimaPontuacao',
        key: 'dataUltimaPontuacao'
    },
    {
        title: 'Ativo',
        dataIndex: 'ativoStr',
        key: 'ativoStr'
    }
];

let interval;


class Beneficiarios extends Component {
    state = {
        loading: true,
        showModal: false,
        isSearching: false,
        pontosValidos: false,
        pontosExpirados: false,
        columns: COLUMNS,
        selectedKey: 'todos',
        tableDataOriginal: [],
        tableData: [],
        searchResult: [],
        record: {}
    }

    constructor(props) {
        super(props);

        this.applyFilter = this.applyFilter.bind(this);
        this.loadMore = this.loadMore.bind(this);

        this.pontosValidos = this.pontosValidos.bind(this);
        this.pontosExpirados = this.pontosExpirados.bind(this);

        this.editarBeneficiario = this.editarBeneficiario.bind(this);
        this.changeLoading = this.changeLoading.bind(this);
        this.updateRecord = this.updateRecord.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.updateList = this.updateList.bind(this);
        this.updateKey = this.updateKey.bind(this);

        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        this.headerElement = React.createRef();
    }

    async componentDidMount() {
        // Buscar beneficiários
        const tableData = await getBeneficiariosConvenio();

        // Adicionar icone de edição de cadastro
        const { columns } = this.state;

        columns.push({
            title: '',
            width: '100 px',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Tooltip placement='topLeft' title='Editar Cadastro'>
                        <Icon
                            type='edit'
                            onClick={() => this.editarBeneficiario(record)}
                        />
                    </Tooltip>
                </span>
            )
        });

        this.setState({
            columns,
            tableData,
            tableDataOriginal: tableData,
            loading: false
        });
    }

    async applyFilter(text) {
        this.setState({ loading: true });

        const {
            tableData,
            pontosValidos,
            pontosExpirados
        } = this.state;

        if (text === '') {
            let { tableDataOriginal } = this.state;

            if (pontosValidos) {
                tableDataOriginal = await getPontosValidos(tableDataOriginal);
            }

            if (pontosExpirados) {
                tableDataOriginal = await getPontosExpirados(tableDataOriginal);
            }

            this.setState({
                tableData: tableDataOriginal,
                tableDataOriginal: tableDataOriginal,
                isSearching: false,
                loading: false
            });

            return;
        }

        let finalWords = [];
        const words = text.split(' ');
        const exceptions = ['', ' ', '-', '/', 'do', 'da', 'de', '*', 'e'];

        words.forEach((word) => {
            if (!exceptions.includes(word)) {
                word = word.toLocaleLowerCase();
                word = word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                finalWords.push(word);
            }
        });

        const keys = [
            'nome',
            'telefone',
            'email'
        ];

        const tableResult = listObj.search(
            tableData,
            text,
            keys
        );

        const alreadyFound = [];
        tableResult.forEach((beneficiario) => {
            alreadyFound.push(beneficiario.key);
        });

        const dbResult = await getBeneficiariosByPalavraChave(
            finalWords,
            alreadyFound,
            pontosValidos,
            pontosExpirados
        );

        const searchResult = tableResult.concat(dbResult);

        searchResult.sort((a, b) => a.nome.localeCompare(b.nome));

        this.setState({
            searchResult,
            tableData: searchResult,
            isSearching: true,
            loading: false
        });

        if (searchResult.length === 0) {
            return {
                message: 'Resultado não Encontrado',
                description: 'Sua busca não retornou nenhum resultado. Por favor, pesquise pelo nome, telefone ou email do beneficiário.'
            };
        } else {
            return {
                message: 'Busca finalizada',
                description: 'Caso não encontre o beneficiário desejado, por favor, refine sua busca.'
            };
        }
    }

    async loadMore() {
        this.setState({ loading: true });

        const {
            tableData,
            tableDataOriginal,
            selectedKey,
            pontosValidos,
            pontosExpirados
        } = this.state;

        const lastDocId = tableData[tableData.length - 1].key;

        let data = [];
        if (selectedKey === 'todos') {
            data = await getBeneficiariosConvenio(
                pontosValidos,
                pontosExpirados,
                lastDocId,
            );
        } else {
            data = await getBeneficiariosByLetter(
                selectedKey.toUpperCase(),
                pontosValidos,
                pontosExpirados,
                lastDocId
            );
        }

        if (data.length === 0) {
            message.warning('Não existe mais clientes a serem carregados');
            this.setState({ loading: false });
            return;
        }

        const newTableData = tableData.concat(data);

        this.setState({
            loading: false,
            tableData: newTableData,
            tableDataOriginal: selectedKey === 'todos' ?
                newTableData :
                tableDataOriginal
        })
    }

    async pontosValidos(pontosValidos) {
        this.setState({ loading: true });

        let { tableData, columns } = this.state;

        if (pontosValidos) {
            columns.splice(5, 0, {
                title: 'Pontos Válidos',
                dataIndex: 'pontosValidos',
                key: 'pontosValidos'
            });

            tableData = await getPontosValidos(tableData);
        } else {
            columns.splice(5, 1);
        }

        this.setState({
            columns,
            pontosValidos,
            tableData,
            loading: false
        });
    }

    async pontosExpirados(pontosExpirados) {
        this.setState({ loading: true });

        let { tableData, columns } = this.state;

        if (pontosExpirados) {
            columns.push({
                title: 'Pontos Expirados',
                dataIndex: 'pontosExpirados',
                key: 'pontosExpirados'
            });

            tableData = await getPontosExpirados(tableData);
        } else {
            columns.pop();
        }

        this.setState({
            columns,
            pontosExpirados,
            tableData,
            loading: false
        });
    }

    editarBeneficiario(record) {
        this.setState({
            record,
            showModal: true
        });
    }

    changeLoading(loading) {
        this.setState({ loading });
    }

    updateRecord(record) {
        let tableData = this.state.tableData;

        this.state.tableData.forEach((item, index) => {
            if (item.key === record.key) {
                tableData[index] = this.updateItem(item, record);
            }
        });

        if (this.state.isSearching) {
            let tableDataOriginal = this.state.tableDataOriginal;

            this.state.tableDataOriginal.forEach((item, index) => {
                if (item.key === record.key) {
                    tableDataOriginal[index] = this.updateItem(item, record);
                }
            });

            this.setState({ tableData, tableDataOriginal });
        } else {
            this.setState({ tableData, tableDataOriginal: tableData });
        }
    }

    updateItem(item, data) {
        const {
            ativo,
            nome,
            sobrenome,
            cpf,
            email,
            endereco
        } = data;

        item.ativo = ativo;
        item.nome = nome;
        item.sobrenome = sobrenome;
        item.cpf = cpf;
        item.email = email;
        item.endereco = endereco;
        item.ativoStr = ativo ? 'Sim' : 'Não';
        item.nomeCompleto = `${nome} ${sobrenome}`;
        item.telefone = endereco.telefone;

        return item;
    }

    updateList(record) {
        this.setState({ tableData: record });
    }

    updateKey(key) {
        this.setState({ selectedKey: key });
    }

    handleOk(record) {
        this.setState({ showModal: false });

        if (record) {
            this.updateRecord(record);
        }
    }

    handleCancel() {
        this.setState({ showModal: false });
    }

    render() {
        return (
            <PageLayout selectItem='beneficiarios' >
                <Modal
                    title='Editar Beneficiário'
                    visible={this.state.showModal}
                    destroyOnClose={true}
                    closable={false}
                    footer={null}

                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <BeneficiariosCRUD
                        record={this.state.record}
                        handleOk={this.handleOk}
                        handleCancel={this.handleCancel}
                    />
                </Modal>

                <Table
                    title={() => (
                        <Header
                            ref={this.headerElement}

                            isSearching={this.state.isSearching}
                            searchResult={this.state.searchResult}
                            tableDataOriginal={this.state.tableDataOriginal}

                            applyFilter={this.applyFilter}

                            pontosValidos={this.pontosValidos}
                            pontosExpirados={this.pontosExpirados}

                            changeLoading={this.changeLoading}
                            updateList={this.updateList}
                            updateKey={this.updateKey}
                        />
                    )}

                    footer={() => (
                        <div className='footerTable'>
                            <Button
                                type='primary'
                                loading={this.state.loading}
                                onClick={this.loadMore}
                                disabled={this.state.isSearching}
                            >
                                Carregar mais Clientes
                            </Button>
                        </div>
                    )}

                    pagination={false}
                    loading={this.state.loading}
                    dataSource={this.state.tableData}
                    columns={this.state.columns}
                />
            </PageLayout>
        )
    }
}

class Header extends Component {
    constructor(props) {
        super(props);

        this.onAlphabetClick = this.onAlphabetClick.bind(this);
        this.exibirPontosValidosChange = this.exibirPontosValidosChange.bind(this);
        this.exibirPontosExpiradosChange = this.exibirPontosExpiradosChange.bind(this);

        this.filterTable = this.filterTable.bind(this);
        this.resetTime = this.resetTime.bind(this);

        this.state = {
            selectedKey: 'todos',
            visible: false,
            exibirPontosValidos: false,
            exibirPontosExpirados: false
        }
    }

    prepareAlphabet = () => {
        let result = [];

        result.push(
            <Button
                type={this.state.selectedKey === 'todos' ? 'primary' : 'outline'}
                key='todos'
                value='todos'
                onClick={this.onAlphabetClick}
            >
                Todos
            </Button>
        );

        for (let i = 65; i < 91; i++) {
            result.push(
                <Button
                    type={this.state.selectedKey === String.fromCharCode(i).toLowerCase() ? 'primary' : 'outline'}
                    key={i}
                    value={String.fromCharCode(i)}
                    onClick={this.onAlphabetClick}
                >
                    {String.fromCharCode(i)}
                </Button>
            );
        }

        return result;
    }

    async onAlphabetClick(e) {
        this.props.changeLoading(true);

        e.persist();

        const {
            isSearching,
            searchResult,
            tableDataOriginal
        } = this.props;

        const {
            exibirPontosValidos,
            exibirPontosExpirados
        } = this.state;

        const key = e.target.value.toLowerCase();
        let clientes = [];

        if (!isSearching) {
            if (key === 'todos') {
                clientes = tableDataOriginal;
            } else {
                clientes = await getBeneficiariosByLetter(
                    key.toUpperCase(),
                    exibirPontosValidos,
                    exibirPontosExpirados
                );
            }
        } else {
            if (key === 'todos') {
                clientes = searchResult;
            } else {
                searchResult.forEach((p) => {
                    if (p.nome.charAt(0).toLowerCase() === key) {
                        clientes.push(p);
                    }
                });
            }
        }

        this.props.changeLoading(false);
        this.props.updateList(clientes);
        this.props.updateKey(key);

        this.setState({ selectedKey: key });
    }

    async exibirPontosValidosChange(obj) {
        const { checked } = obj.target;

        const message = 'Essa consulta é mais demorada dependendo de quantos clientes estejam na tabela. Deseja continuar?'
        if (checked && !(await mensagem.confirmar(message))) return;

        this.props.pontosValidos(checked);
        this.setState({ exibirPontosValidos: checked });
    }

    async exibirPontosExpiradosChange(obj) {
        const { checked } = obj.target;

        const message = 'Essa consulta é mais demorada dependendo de quantos clientes estejam na tabela. Deseja continuar?'
        if (checked && !(await mensagem.confirmar(message))) return;

        this.setState({ exibirPontosExpirados: obj.target.checked });
        this.props.pontosExpirados(checked);
    }

    async filterTable(text) {
        clearInterval(interval);

        const searchWarning = await this.props.applyFilter(text);

        if (searchWarning) {
            notification.open(searchWarning);
        }
    }

    async resetTime(obj) {
        clearInterval(interval);

        interval = setInterval(this.filterTable, 3000, obj.target.value);

        this.setState({ selectedKey: 'todos' });
    }

    render() {
        return (
            <div className='filterContainer'>
                <div className='noteMessage'>
                    Os dados expostos no relatório podem levar até 5 horas para serem atualizados.
                </div>

                <div className='searchContainer'>
                    <Search
                        allowClear
                        placeholder='Procurar Beneficiário'
                        onSearch={this.filterTable}
                        onChange={this.resetTime}
                        style={{ marginLeft: '10px', width: 200 }}
                    />

                    <div className='alphabet'>
                        {this.prepareAlphabet()}
                    </div>
                </div>

                <div className='checkboxContainer'>
                    <Checkbox
                        onChange={this.exibirPontosValidosChange}
                        checked={this.state.exibirPontosValidos}
                    >
                        Mostrar pontos válidos?
                    </Checkbox>

                    <Checkbox
                        onChange={this.exibirPontosExpiradosChange}
                        checked={this.state.exibirPontosExpirados}
                    >
                        Mostrar pontos expirados?
                    </Checkbox>
                </div>
            </div>
        )
    }
}

export default withRouter(Beneficiarios);