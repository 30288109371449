import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout';
import BarChart from '../../../components/charts/barChart';
import { DatePicker, Button, message, Table } from 'antd';
import { buildChart } from './FidelidadeF';
import locale from 'antd/es/date-picker/locale/pt_BR';
import vertteStorage from '../../../components/localstorage/localstorage';
import moment from 'moment';
import './Fidelidade.css'
import { getConvenio } from '../../../components/auth/auth-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';


const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY'

// const CINZA = "#9d9e9d";
const VERDE = '#32a852';
const LARANJA = '#ebb134';
const ROXO = '#a234eb';
const VERMELHO = '#D9534F';

class Fidelidades extends React.Component {

    state = {
        loading: true,
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        data: {
            labels: [],
            datasets: []
        },
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.onDateChange = this.onDateChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.mountChart = this.mountChart.bind(this);
        this.buildColums = this.buildColums.bind(this);
    }

    async componentDidMount() {
        const storage = JSON.parse(vertteStorage.get('@reports/fidelidade'));
        if (storage) {
            this.setState({
                periodo: {
                    inicial: moment(storage.periodo.inicial),
                    final: moment(storage.periodo.final),
                },
                loading: false,
            });

            this.mountChart(storage.chartData)
        } else {
            this.setState({ loading: false });
        }
    }

    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }
        this.setState({ periodo, alterouConfiguracaoRelatorio: true });
    }

    async onFilterClick() {

        this.buildColums();

        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning("Selecione um período");
            return;
        }

        this.setState({ loading: true })

        const convenio = getConvenio();
        const conveniosIds = [];
        conveniosIds.push({ key: convenio.key });

        const data = await buildChart(
            this.state.periodo.inicial,
            this.state.periodo.final,
            convenio.ownerId,
            conveniosIds,
            false
        );

        this.mountChart(data.chartData);

        const tableData = data.tableData;

        const storageElement = {
            periodo: this.state.periodo,
            chartData: data.chartData,
            tableData: tableData,
        }
        vertteStorage.set('@reports/fidelidade', JSON.stringify(storageElement));
        this.setState({ tableData, loading: false });
    }

    async mountChart(chartData) {
        const data = {
            labels: [],
            datasets: [
                {
                    label: "Pontos",
                    data: [],
                    fill: true,
                    backgroundColor: "#f0e3c4",
                    borderColor: LARANJA,
                },
                {
                    label: "Resgates",
                    data: [],
                    fill: false,
                    hidden: false,
                    borderColor: VERDE,
                    backgroundColor: "#99b3a0",
                },
                {
                    label: "Novos Clientes",
                    data: [],
                    fill: false,
                    hidden: false,
                    borderColor: ROXO,
                    backgroundColor: "#cba9e2",
                },
                {
                    label: "Valores Movimentados",
                    data: [],
                    fill: false,
                    hidden: true,
                    borderColor: VERMELHO,
                    backgroundColor: "#f3a19e",
                }
            ]
        };

        let valores = {
            pontos: 0,
            baixados: 0,
            novosClientes: 0,
            valor: 0,
        }
        chartData.forEach((item) => {
            valores.pontos += item.pontos;
            valores.baixados += item.baixados;
            valores.novosClientes += item.novosClientes;
            valores.valor += item.valueInCents;
            data.datasets[0].data.push(item.pontos);
            data.datasets[1].data.push(item.baixados);
            data.datasets[2].data.push(item.novosClientes);
            data.datasets[3].data.push(item.valueInCents / 100);
            const date = new Date(item.data);
            data.labels.push(date.getDate() + "/" + (date.getMonth() + 1))
        });
        valores.valor = beneficiarioDB.formatarMoeda(valores.valor / 100);
        this.setState({ data });
    }

    buildColums() {
        const columns = [
            {
                title: 'Convênio',
                dataIndex: 'convenio.nome',
                key: 'convenio.nome'
            },
            {
                title: 'Pontos',
                dataIndex: 'pontos',
                key: 'pontos'
            },
            {
                title: 'Resgates',
                dataIndex: 'baixados',
                key: 'baixados'
            },
            {
                title: 'Valores Movimentados',
                dataIndex: 'valueStr',
                key: 'valueStr'
            }

        ]

        this.setState({ columns })
    }


    render() {
        return (
            <div>
                <PageLayout selectItem={"relatorios/fidelidade"}>
                    <div className="containerMovimentacao">
                        <label>Período: </label>
                        <RangePicker
                            style={{ width: '25%' }}
                            locale={locale}
                            disabled={this.state.loading}
                            format={dateFormat}
                            placeholder={['Data Inicial', 'Data Final']}
                            onChange={this.onDateChange}
                            value={[this.state.periodo.inicial, this.state.periodo.final]}
                        />
                        <Button loading={this.state.loading} onClick={this.onFilterClick} type="primary">Filtrar</Button>
                    </div>

                    <label className='subtituloMovimentos'>Fidelização por dia</label>
                    <BarChart className="chartMovimento"
                        data={this.state.data}

                        options={{
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {

                                        let label = data.datasets[tooltipItem.datasetIndex].label;
                                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                        value = beneficiarioDB.formatarMoeda(value / 100);
                                        return ' ' + label + ': ' + value
                                    }
                                }
                            },
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        }

                                    }
                                ]
                            },
                            layout: {
                                padding: {
                                    top: 20,
                                    right: 40,
                                    left: 40,
                                    bottom: 20,
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    anchor: "end",
                                    align: "top",
                                    formatter: function (value, context) {
                                        if (value === undefined) {
                                            return 0
                                        } else {
                                            if (context.dataset.label === 'Valores Movimentados') {
                                                return value = `${beneficiarioDB.formatarMoeda(value)}`;
                                            } else if (context.dataset.label === 'Novos Clientes') {
                                                return value;
                                            } else {
                                                return value = `${value} pts`;
                                            }
                                        }
                                    }
                                },
                            },
                        }}
                    />

                    <label className='subtituloMovimentos'>Fidelização por convênio</label>
                    <div className="tableEstabelecimentos">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                        >

                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(Fidelidades);

