import React from 'react';
import { withRouter } from 'react-router';
import { DatePicker, Table, Button, message, Card, Checkbox, Tabs, Icon, Modal, Select } from 'antd';
import { getBalance, getBeneficiario, getDataByCPF, getResgatesByCPF, getData, getResgates } from './ExtratoF';
import { getColor, getSmoothColor } from '../../../components/layout/themes/LayoutCSS';
import { getConvenio } from '../../../components/auth/auth-provider';

import locale from 'antd/es/date-picker/locale/pt_BR';
import funcs from '../../../components/funcs/funcs';
import PageLayout from '../../../components/layout/PageLayout'
import jsPDF from "jspdf";
import vertteStorage from '../../../components/localstorage/localstorage';
import moment from 'moment';
import SearchInput from '../../../components/input/SearchInput';
import selectData from '../../../components/selectData/selectData';

import "jspdf-autotable";
import './Extrato.css'


const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

const key = 'updatemessage';
const dateFormat = 'DD/MM/YYYY'

const COLUMNS = [
    {
        title: 'Data e Hora',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Beneficiário',
        dataIndex: 'beneficiario',
        key: 'beneficiario',
    },
    {
        title: 'Operador',
        dataIndex: 'operador',
        key: 'operador',
    },
    {
        title: 'Valor da Venda',
        dataIndex: 'valor',
        key: 'valor',
    },
    {
        title: 'Número do Documento',
        dataIndex: 'nrDocumento',
        key: 'nrDocumento',
    },
    {
        title: 'Expira em',
        dataIndex: 'expiraEm',
        key: 'expiraEm',
    },
    {
        title: 'Pontos Ganhos',
        dataIndex: 'pontos',
        key: 'pontos',
    },
    {
        title: 'Estornado?',
        dataIndex: 'estornou',
        key: 'estornou',
    },
    {
        title: 'Data do Estorno',
        dataIndex: 'dataEstorno',
        key: 'dataEstorno',
    }
];

const COLUMNS_AWARDS = [
    {
        title: 'Prêmio',
        dataIndex: 'award',
        key: 'award',
    },
    {
        title: 'Pontos',
        dataIndex: 'score',
        key: 'score',
    },
];

const COLUMNS_RESGATES = [
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Data do Estorno',
        dataIndex: 'dataEstorno',
        key: 'dataEstorno',
    },
    {
        title: 'Pontos',
        dataIndex: 'pontos',
        key: 'pontos',
    },
    {
        title: 'Estornado?',
        dataIndex: 'estornou',
        key: 'estornou',
    },
    {
        title: 'Transação',
        dataIndex: 'resgateId',
        key: 'resgateId',
    },
];

class Extrato extends React.Component {

    state = {
        loading: false,
        tableData: [],
        resgatesData: [],
        periodo: {
            inicial: undefined,
            final: undefined,
        },
        beneficiario: {
            nome: '',
            sobrenome: '',
            search: ''
        },
        dataScore: {
            canRescue: false,
            score: 0,
            awards: [],
        },
        configs: {
            exibirResgates: false,
            periodoPorMes: false,
            colunas: [
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true
            ]
        },
        showConfig: false,
        filtrarPorPeriodo: false,
        buscarTodasPontuacoes: false,
        searchType: 'cpf',
        mask: '999.999.999-99',
        columns: [...COLUMNS],
        awardsColumns: COLUMNS_AWARDS,
        resgatesColumns: COLUMNS_RESGATES,
        activeTab: 'pontos'
    }

    periodoOptions = [];

    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchTypeChange = this.onSearchTypeChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onDateChangeMes = this.onDateChangeMes.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onColunaChange = this.onColunaChange.bind(this);

        this.handleModalOk = this.handleModalOk.bind(this);
        this.handleModalCancel = this.handleModalCancel.bind(this);

        this.exibirResgatesChange = this.exibirResgatesChange.bind(this);
        this.extratoPeriodoChange = this.extratoPeriodoChange.bind(this);
        this.buscarTodasPontuacoesChange = this.buscarTodasPontuacoesChange.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.modificarFiltroPeriodo = this.modificarFiltroPeriodo.bind(this);
        this.modificarColunas = this.modificarColunas.bind(this);
    }

    async componentDidMount() {
        // Carregar as opções salvas da configuração do relatório
        const configs = JSON.parse(vertteStorage.get('configs'));

        if (configs) {
            const { exibirResgates } = configs;

            this.modificarColunas(configs);

            if (exibirResgates) {
                this.exibirResgatesChange(exibirResgates);
            }

            this.setState({ configs });
        }

        // Opções para o Select
        const meses = [
            'Janeiro', 'Fevereiro', 'Março',
            'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro',
            'Outubro', 'Novembro', 'Dezembro'
        ]

        for (let i = 0; i < meses.length; i++) {
            this.periodoOptions.push(
                <Option key={meses[i]} value={i}>
                    {meses[i]}
                </Option>
            )
        }
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            // this.onFilterClick();
        }
    }

    onSearchChange(e) {
        const beneficiario = this.state.beneficiario;
        beneficiario.search = e.target.value;
        this.setState({ beneficiario });
    }

    onSearchTypeChange(type) {
        const beneficiario = this.state.beneficiario;
        beneficiario.search = '';

        let mask = '';

        switch (type) {
            case 'cnpj':
                mask = '99.999.999/9999-99';
                break;

            case 'telefone':
                mask = '(99) 99999-9999';
                break;

            case 'nome':
                mask = '';
                break;

            default:
                mask = '999.999.999-99';
                break;
        }

        this.setState({
            mask,
            beneficiario,
            searchType: type
        });
    }

    onTabChange(activeTab) {
        this.setState({ activeTab });
    }

    exibirResgatesChange(obj) {
        const exibirResgates = obj.target ? obj.target.checked : obj;
        const { configs, columns } = this.state;

        configs.exibirResgates = exibirResgates;

        if (exibirResgates) {
            columns.push({
                title: 'Resgatou?',
                dataIndex: 'resgatou',
                key: 'resgatou',
            });
        } else {
            columns.pop();
        }

        const beneficiario = this.state.beneficiario;
        beneficiario.nome = '';
        beneficiario.sobrenome = '';

        const tableData = [];
        const dataScore = this.state.dataScore.awards;

        dataScore.awards = [];

        let activeTab = this.state.activeTab;
        if (activeTab === 'resgates') {
            activeTab = 'pontos';
        }

        this.setState({ configs, columns, beneficiario, tableData, dataScore, activeTab });
    }

    modificarFiltroPeriodo(obj) {
        const { checked } = obj.target;
        const config = this.state.configs;

        config.periodoPorMes = checked;

        this.setState({ config });
    }

    modificarColunas(configs) {
        const { columns } = this.state;
        const { colunas } = configs;

        let numeroColunas = 0
        for (let i = 0; i < colunas.length; i++) {
            if (colunas[i]) {
                numeroColunas += 1;
            }
        }

        if (numeroColunas < 1) {
            message.warning('A tabela deve possuir pelo menos uma coluna');
            return false;
        }

        for (let i = 0; i < colunas.length; i++) {
            if (colunas[i]) {
                columns[i] = COLUMNS[i];
            } else {
                columns[i] = {};
            }
        }

        this.setState({ columns });
        return true;
    }

    extratoPeriodoChange(obj) {
        const { checked } = obj.target;
        let { periodo } = this.state;

        if (!checked) {
            periodo.inicial = undefined;
            periodo.final = undefined;
        }

        this.setState({ filtrarPorPeriodo: checked, periodo });
    }

    buscarTodasPontuacoesChange(obj) {
        let beneficiario = this.state.beneficiario;
        beneficiario.search = '';

        this.setState({
            beneficiario,
            buscarTodasPontuacoes: obj.target.checked,
            filtrarPorPeriodo: false
        });
    }

    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }
        this.setState({ periodo });
    }

    onDateChangeMes(value) {
        const date = new Date();

        const inicial = new Date(date.getFullYear(), value, 1);
        const final = new Date(date.getFullYear(), value + 1, 1);

        const periodo = {
            inicial: moment(inicial),
            final: moment(final)
        };

        this.setState({ periodo });
    }

    onColunaChange(obj, index) {
        const { configs } = this.state;
        const { checked } = obj.target;

        configs.colunas[index] = checked;

        this.setState({ configs });
    }

    async onFilterClick() {

        if ((this.state.filtrarPorPeriodo || this.state.buscarTodasPontuacoes) && (!this.state.periodo.inicial || !this.state.periodo.final)) {
            message.warning("Selecione um período");
            return;
        }

        if (!this.state.beneficiario.search) {
            if (!this.state.buscarTodasPontuacoes) {
                message.warning("Informe algum dado do beneficiário ou habilite a busca por todas as pontuações");
                return;
            }

            const difDias = Math.abs(new Date(this.state.periodo.final) - new Date(this.state.periodo.inicial));
            const diasPeriodo = Math.ceil(difDias / (1000 * 60 * 60 * 24));

            if (diasPeriodo > 32) {
                message.warning('Período não pode ser maior que 31 dias ao buscar por todos os pontos');
                return;
            }

            const tableData = await getData(
                this.state.configs.exibirResgates,
                this.state.periodo.inicial,
                this.state.periodo.final);

            let resgatesData = [];

            if (this.state.configs.exibirResgates) {
                resgatesData = await getResgates(
                    this.state.periodo.inicial,
                    this.state.periodo.final);
            }

            const beneficiario = {
                nome: '',
                sobrenome: '',
                cpf: ''
            };

            this.setState({
                beneficiario,
                tableData,
                resgatesData,
                activeTab: 'pontos',
                loading: false
            });
            return;
        }

        const { searchType } = this.state;
        const { search } = this.state.beneficiario;
        let isOk;

        switch (searchType) {
            case 'cnpj':
                isOk = await funcs.isCNPJ(search);

                if (!isOk) {
                    message.warning('CNPJ inválido');
                    return;
                };

                break;

            case 'telefone':
                if (search.replaceAll('_', '').length !== 15) {
                    message.warning('Telefone inválido');
                    return;
                };

                break;

            case 'nome':
                if (search === ' ') {
                    message.warning('Campo de pesquisa por nome vazio');
                    return;
                }

                break;

            default:
                isOk = await funcs.isCPF(search);

                if (!isOk) {
                    message.warning('CPF inválido');
                    return;
                };

                break;
        }

        this.setState({ loading: true });

        let beneficiario = await getBeneficiario(search, searchType);

        if (!beneficiario || beneficiario.length === 0) {
            message.warning('Não foi possível encontrar um beneficiário com o dado informado');
            this.setState({ loading: false });
            return;
        }

        if (beneficiario.length > 1) {
            const index = await selectData.modal(beneficiario);

            if (index) {
                beneficiario = beneficiario[index];
            } else {
                this.setState({ loading: false });
                return;
            }
        } else if (beneficiario.length) {
            beneficiario = beneficiario[0];
        }

        const tableData = await getDataByCPF(
            beneficiario.key,
            this.state.configs.exibirResgates,
            this.state.periodo.inicial,
            this.state.periodo.final);

        let resgatesData = [];
        if (this.state.configs.exibirResgates) {
            resgatesData = await getResgatesByCPF(
                beneficiario.key,
                this.state.periodo.inicial,
                this.state.periodo.final);
        }

        const dataScore = await getBalance(
            beneficiario.cpf !== '' ? beneficiario.cpf.replace(/[^\d]/g, '') : beneficiario.endereco.telefone,
            beneficiario.cpf !== '' ? false : true
        );

        beneficiario.search = search;

        this.setState({ beneficiario, tableData, dataScore, activeTab: 'pontos', resgatesData, loading: false });
    }

    exportPDF() {
        if (this.state.tableData.length === 0 && this.state.resgatesData.length === 0) {
            message.warn({ content: 'Não há dados para exportar', key });
            return;
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const convenio = getConvenio();

        const title = this.state.buscarTodasPontuacoes ?
            `Relatório de Extrato - ${convenio.nomeFantasia}` :
            `Extrato de pontos de ${this.state.beneficiario.nome} - ${convenio.nomeFantasia}`;

        const headers = [['Data e Hora', 'Beneficiário', 'Operador', 'Valor da Venda', 'Número do Documento', 'Expira Em', 'Pontos Ganhos', 'Estornado?', 'Data do Estorno', 'Resgatou?']];
        const headers2 = [['Data', 'Pontos Trocados', 'Estornado?', 'Data do Estorno', 'Transação']];

        const source = [];
        const source2 = [];

        for (let index = 0; index < this.state.tableData.length; index++) {
            const ponto = this.state.tableData[index];
            const item = {
                data: ponto.data,
                beneficiario: ponto.beneficiario,
                valor: ponto.valor,
                nrDocumento: ponto.nrDocumento,
                estornou: ponto.estornou,
                dataEstorno: ponto.dataEstorno,
                expiraEm: ponto.expiraEm,
                pontos: ponto.pontos,
                operador: ponto.operador,
                resgatou: ponto.resgatou,
            }
            source.push(item);
        }

        let totalStr = this.state.tableData.length > 1 ? ' registros' : this.state.tableData.length === 1 ? ' registro' : '';
        totalStr = `${this.state.tableData.length} ${totalStr}`;

        const total = {
            data: `Total: ${totalStr}`,
            beneficiario: '',
            valor: '',
            nrDocumento: '',
            dataEstorno: '',
            expiraEm: '',
            pontos: '',
            estornou: '',
            operador: '',
            resgatou: '',
        }
        source.push(total);

        const data = source.map(elt => [elt.data, elt.beneficiario, elt.operador, elt.valor, elt.nrDocumento, elt.expiraEm, elt.pontos, elt.expiraEm, elt.dataEstorno, elt.resgatou]);
        let data2 = [];

        if (this.state.resgatesData.length > 0) {
            for (let index = 0; index < this.state.resgatesData.length; index++) {
                const resgate = this.state.resgatesData[index];
                const item = {
                    data: resgate.data,
                    dataEstorno: resgate.dataEstorno,
                    pontos: resgate.pontos,
                    estornou: resgate.estornou,
                    transacao: resgate.resgateId,
                }
                source2.push(item);
            }

            data2 = source2.map(elt => [elt.data, elt.dataEstorno, elt.pontos, elt.estornou, elt.transacao]);
        }

        doc.text(title, marginLeft, 40);

        doc.autoTable({
            startY: 50,
            head: headers,
            body: data,
        });

        if (this.state.resgatesData.length > 0) {
            doc.text("----- Final Pontos Ganhos ----- ", 40, doc.autoTable.previous.finalY + 30);
            doc.autoTable({
                theme: 'striped',
                head: headers2,
                body: data2,
                pageBreak: 'always',
            });
        }

        doc.save(`Extrato de pontos de ${this.state.beneficiario.nome}.pdf`);
    }

    handleModalOk() {
        const { configs } = this.state;

        const isOk = this.modificarColunas(configs);

        if (isOk) {
            vertteStorage.set('configs', JSON.stringify(configs));
            this.setState({ showConfig: false });
        }
    }

    handleModalCancel() {
        let configs = JSON.parse(vertteStorage.get('configs'));

        if (!configs) {
            configs = {
                exibirResgates: false,
                periodoPorMes: false,
                colunas: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true
                ]
            }
        }

        if (this.state.configs.exibirResgates !== configs.exibirResgates) {
            this.exibirResgatesChange(configs.exibirResgates);
        }

        this.setState({ configs, showConfig: false });
    }

    render() {
        return (
            <PageLayout selectItem="relatorios/saldos" >
                <Modal
                    className='modalConfig'
                    title='Configurações do Relatório de Extrato'
                    visible={this.state.showConfig}
                    closable={false}
                    onOk={this.handleModalOk}
                    onCancel={this.handleModalCancel}
                >
                    <div className='containerOptions'>
                        <label className='title'>
                            Opções Avançadas
                        </label>

                        <div>
                            <Checkbox
                                className='checkbox'
                                onChange={this.exibirResgatesChange}
                                checked={this.state.configs.exibirResgates}
                                disabled={this.state.loading}
                            >
                                Mostrar resgates?
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={this.modificarFiltroPeriodo}
                                checked={this.state.configs.periodoPorMes}
                                disabled={this.state.loading}
                            >
                                Selecionar período por mês?
                            </Checkbox>
                        </div>
                    </div>

                    <div className='containerOptions'>
                        <label className='title'>
                            Opções de Colunas da Tabela de Pontos
                        </label>

                        <div>
                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 0)}
                                checked={this.state.configs.colunas[0]}
                                disabled={this.state.loading}
                            >
                                Data e Hora
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 1)}
                                checked={this.state.configs.colunas[1]}
                                disabled={this.state.loading}
                            >
                                Beneficiário
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 2)}
                                checked={this.state.configs.colunas[2]}
                                disabled={this.state.loading}
                            >
                                Operador
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 3)}
                                checked={this.state.configs.colunas[3]}
                                disabled={this.state.loading}
                            >
                                Valor da Venda
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 4)}
                                checked={this.state.configs.colunas[4]}
                                disabled={this.state.loading}
                            >
                                Número do Documento
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 5)}
                                checked={this.state.configs.colunas[5]}
                                disabled={this.state.loading}
                            >
                                Expira em
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 6)}
                                checked={this.state.configs.colunas[6]}
                                disabled={this.state.loading}
                            >
                                Pontos Ganhos
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 7)}
                                checked={this.state.configs.colunas[7]}
                                disabled={this.state.loading}
                            >
                                Estornado
                            </Checkbox>

                            <Checkbox
                                className='checkbox'
                                onChange={(obj) => this.onColunaChange(obj, 8)}
                                checked={this.state.configs.colunas[8]}
                                disabled={this.state.loading}
                            >
                                Data do Estorno
                            </Checkbox>
                        </div>
                    </div>
                </Modal>

                <div className="containerSaldos">
                    <div className='containerOptions'>
                        <Select
                            style={{ width: '6rem' }}
                            value={this.state.searchType}
                            onChange={this.onSearchTypeChange}
                            disabled={this.state.loading || this.state.buscarTodasPontuacoes}
                        >
                            <Option key='cpf' value='cpf'>
                                CPF
                            </Option>

                            <Option key='cnpj' value='cnpj'>
                                CNPJ
                            </Option>

                            <Option key='telefone' value='telefone'>
                                Telefone
                            </Option>

                            <Option key='nome' value='nome'>
                                Nome
                            </Option>
                        </Select>

                        <SearchInput
                            placeholder={''}
                            className='inputPoint inputCPF'
                            value={this.state.beneficiario.search}
                            mask={this.state.mask}
                            disabled={this.state.loading || this.state.buscarTodasPontuacoes}
                            onChange={this.onSearchChange}
                            onKeyDown={this.onKeyDown}
                        />

                        <Checkbox
                            onChange={this.buscarTodasPontuacoesChange}
                            checked={this.state.buscarTodasPontuacoes}
                            disabled={this.state.loading}
                        >Buscar todos os pontos?</Checkbox>

                        <Checkbox
                            onChange={this.extratoPeriodoChange}
                            checked={this.state.filtrarPorPeriodo}
                            disabled={this.state.loading || this.state.buscarTodasPontuacoes}
                        >Período (opcional)</Checkbox>
                    </div>

                    <div className='containerOptions'>
                        {this.state.configs.periodoPorMes ?
                            <div className='datePicker'>
                                <Select
                                    placeholder='Selecionar Mês'
                                    style={{ width: '100%' }}
                                    onChange={this.onDateChangeMes}
                                    disabled={(this.state.loading || !this.state.filtrarPorPeriodo) && !this.state.buscarTodasPontuacoes}
                                >
                                    {this.periodoOptions}
                                </Select>
                            </div>
                            :
                            <RangePicker
                                className='datePicker'
                                locale={locale}
                                disabled={(this.state.loading || !this.state.filtrarPorPeriodo) && !this.state.buscarTodasPontuacoes}
                                format={dateFormat}
                                placeholder={['Data Inicial', 'Data Final']}
                                onChange={this.onDateChange}
                                value={[this.state.periodo.inicial, this.state.periodo.final]}
                            />
                        }

                        <Button type="primary" disabled={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>
                        <Button type="primary" disabled={this.state.tableData.length === 0 && this.state.resgatesData.length === 0} onClick={() => this.exportPDF()}>Exportar para PDF</Button>

                        <Icon
                            className='configIcon'
                            type='setting'
                            onClick={() => this.setState({ showConfig: true })}
                        />
                    </div>
                </div>

                {this.state.beneficiario.nome !== '' ?

                    <div className='cards'>
                        <Card
                            className="cardBeneficiario"
                            title="Participante"
                            headStyle={{ background: getColor(), border: 0, color: 'white' }}
                            style={{ width: '18rem', flexGrow: 1, marginRight: '1.25rem' }}
                        >
                            <div className="labelNomeBeneficiario">
                                <label>{`${this.state.beneficiario.nome} ${this.state.beneficiario.sobrenome}`}</label>
                            </div>

                        </Card>

                        <Card
                            className="cardPontos"
                            title="Pontos Válidos"
                            headStyle={{ background: getColor(), border: 0, color: 'white' }}
                            style={{ background: getSmoothColor() }}
                        >
                            <div className="labelSaldo">
                                <label>{this.state.dataScore.score}</label>
                            </div>
                        </Card>

                    </div>
                    : ''
                }

                <div className="tableSaldos">

                    <Tabs defaultActiveKey="pontos" activeKey={this.state.activeTab} onChange={this.onTabChange}>

                        <TabPane tab="Pontos" key="pontos">
                            <Table
                                pagination={{
                                    defaultPageSize: 100,
                                    position: 'both',
                                    pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                                    showSizeChanger: true,
                                    locale: { items_per_page: "" }
                                }}
                                loading={this.state.loading}
                                dataSource={this.state.tableData}
                                columns={this.state.columns}
                                rowClassName={(record) => record.color.replace('#', '')}
                                rowKey={(record) => record.key}
                            >
                            </Table>
                        </TabPane>

                        {this.state.buscarTodasPontuacoes ? null : (
                            <TabPane tab="Prêmios disponíveis 🎁" key="premios">

                                <Table
                                    pagination={{
                                        defaultPageSize: 100,
                                        position: 'both',
                                        pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                                        showSizeChanger: true,
                                        locale: { items_per_page: "" }
                                    }}
                                    loading={this.state.loading}
                                    dataSource={this.state.dataScore.awards}
                                    columns={this.state.awardsColumns}
                                    rowClassName={(record) => record.color.replace('#', '')}
                                    rowKey={(record) => record.key}
                                >
                                </Table>
                            </TabPane>
                        )}

                        {this.state.configs.exibirResgates ?

                            <TabPane tab="Resgates" key="resgates">
                                <Table
                                    pagination={{
                                        defaultPageSize: 100,
                                        position: 'both',
                                        pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                                        showSizeChanger: true,
                                        locale: { items_per_page: "" }
                                    }}
                                    loading={this.state.loading}
                                    dataSource={this.state.resgatesData}
                                    columns={this.state.resgatesColumns}
                                    rowClassName={(record) => record.color.replace('#', '')}
                                    rowKey={(record) => record.key}
                                >
                                </Table>




                            </TabPane>

                            : ''
                        }

                    </Tabs>
                </div>

            </PageLayout>
        )
    }

}

export default withRouter(Extrato);