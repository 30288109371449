import React, { Component } from 'react';
import { Modal, Table, Checkbox, notification } from 'antd';
import Search from 'antd/lib/input/Search';
import listObj from '../listSearch/listSearch';
import './selectData.css';

const { confirm } = Modal
let interval;


class SelectData {
    async modal(data) {
        const tableData = [];
        let index = 0;

        for (let i = 0; i < data.length; i++) {
            const {
                nome,
                sobrenome,
                key
            } = data[i];

            tableData.push({
                key,
                index: i,
                beneficiario: `${nome} ${sobrenome}`,
                disabled: false,
                checked: false
            });
        }

        return new Promise((resolve, reject) => {
            confirm({
                title: 'Selecione qual você deseja visualizar',
                okText: 'Confirmar',
                cancelText: 'Cancelar',
                width: '40%',
                content:
                    <Content
                        tableData={tableData}
                        changeIndex={(value) => index = value}
                    />
                ,
                async onOk() {
                    resolve(index.toString());
                },
                onCancel() {
                    resolve(undefined);
                }
            })
        }).catch((error) => {
            console.log(error);
            return;
        });
    }
}

class Content extends Component {
    state = {
        tableData: [],
        tableDataOriginal: [],
        loading: false
    }

    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
        this.filterTable = this.filterTable.bind(this);
        this.resetTime = this.resetTime.bind(this);
    }

    componentDidMount() {
        const { tableData } = this.props;

        this.setState({
            tableData,
            tableDataOriginal: tableData,
        });
    }

    async select(e, item) {
        this.setState({ loading: true });

        setTimeout(() => {
            const { checked } = e.target;
            const { tableData } = this.state;
            let index = 0;

            if (checked) {
                index = item.index;

                for (let i = 0; i < tableData.length; i++) {
                    if (tableData[i].key !== item.key) {
                        tableData[i].disabled = true;
                    } else {
                        tableData[i].checked = true;
                    }
                }
            } else {
                for (let i = 0; i < tableData.length; i++) {
                    tableData[i].disabled = false;
                }
            }

            this.setState({
                tableData,
                loading: false
            });

            this.props.changeIndex(index);
        }, 500);
    }

    async filterTable(text) {
        clearInterval(interval);

        this.setState({ loading: true });

        const {
            tableData,
            tableDataOriginal
        } = this.state;

        if (text === '') {
            this.setState({
                tableData: tableDataOriginal,
                loading: false
            });

            return;
        }

        const words = text.split(' ');
        const exceptions = ['', ' ', '-', '/', 'do', 'da', 'de', '*', 'e'];

        words.forEach((word) => {
            if (!exceptions.includes(word)) {
                word = word.toLocaleLowerCase();
                word = word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }
        });

        const keys = ['beneficiario'];

        const tableResult = listObj.search(
            tableData,
            text,
            keys
        );

        tableResult.sort((a, b) => a.beneficiario.localeCompare(b.beneficiario));

        this.setState({
            tableData: tableResult,
            loading: false
        });

        if (tableResult.length === 0) {
            notification.open({
                message: 'Resultado não Encontrado',
                description: 'Sua busca não retornou nenhum resultado. Por favor, pesquise pelo nome do beneficiário.'
            });
        } else {
            notification.open({
                message: 'Busca finalizada',
                description: 'Caso não encontre o beneficiário desejado, por favor, refine sua busca.'
            });
        }
    }

    async resetTime(obj) {
        clearInterval(interval);
        interval = setInterval(this.filterTable, 3000, obj.target.value);
    }

    render() {
        const COLUMNS = [
            {
                title: 'Beneficiário',
                dataIndex: 'beneficiario',
                key: 'beneficiario'
            },
            {
                title: '',
                width: '1.25rem',
                key: 'checkbox',
                render: (item) => (
                    <Checkbox
                        disabled={item.disabled}
                        checked={item.checked}
                        onChange={(e) => this.select(e, item)}
                    />
                ),
            }
        ];

        return (
            <div>
                <p className='textDescription' >
                    Encontramos mais de um resultado para a sua busca. Por favor, selecione qual você deseja visualizar.
                </p>

                <Search
                    allowClear
                    placeholder='Procurar Beneficiário'
                    onSearch={this.filterTable}
                    onChange={this.resetTime}
                    style={{ width: '100%', marginBottom: '0.625rem' }}
                />

                <Table
                    columns={COLUMNS}
                    dataSource={this.state.tableData}
                    loading={this.state.loading}
                    pagination={{
                        defaultPageSize: 5,
                        position: 'bottom'
                    }}
                />
            </div>
        )
    }
}

const selectData = new SelectData();
export default selectData;