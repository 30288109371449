import { getConvenio } from '../../components/auth/auth-provider';
import beneficiarioDB from '../../dataManager/dtmBeneficiario';
import pontoDB from '../../dataManager/dtmPontos';


export async function getBeneficiariosConvenio(pontosValidos, pontosExpirados, lastDocId) {
    const { ownerId, key } = getConvenio();

    const beneficiarios = await beneficiarioDB.getAtivosByConvenioIdLimited(
        ownerId,
        key,
        50,
        lastDocId
    );

    const tableData = await getTableData(beneficiarios, pontosValidos, pontosExpirados);

    return tableData;
}

export async function getBeneficiariosByPalavraChave(words, alreadyFound, pontosValidos, pontosExpirados) {
    const { ownerId, key } = getConvenio();

    const beneficiarios = await beneficiarioDB.getByPalavraChaveLimited(
        ownerId,
        key,
        words,
        alreadyFound,
        100
    );

    let tableData = [];
    if (beneficiarios.length > 0) {
        tableData = await getTableData(beneficiarios, pontosValidos, pontosExpirados);
    }

    return tableData;
}

export async function getBeneficiariosByLetter(letter, pontosValidos, pontosExpirados, lastDocId) {
    const { ownerId, key } = getConvenio();

    const beneficiarios = await beneficiarioDB.getByLetterLimited(
        ownerId,
        key,
        letter,
        50,
        lastDocId
    );

    let tableData = [];
    if (beneficiarios.length > 0) {
        tableData = await getTableData(beneficiarios, pontosValidos, pontosExpirados);
    }

    return tableData;
}

async function getTableData(beneficiarios, pontosValidos, pontosExpirados) {
    const tableData = [];

    for (let i = 0; i < beneficiarios.length; i++) {
        const {
            ativo,
            cadastro,
            cpf,
            endereco,
            email,
            key,
            nome,
            sobrenome
        } = beneficiarios[i];

        const { administrador } = getConvenio();

        const ultimaPontuacao = await pontoDB.getLastRegisterByParticipante(key, true);

        if (!ultimaPontuacao) {
            continue;
        }

        let primeiraPontuacao;
        if (!cadastro) {
            primeiraPontuacao = await pontoDB.getFirstRegisterByParticipante(key);
        }

        let pontosValidosData;
        if (pontosValidos) {
            pontosValidosData = await pontoDB.getPoints(
                key,
                administrador.key
            );
        }

        let pontosExpiradosData;
        if (pontosExpirados) {
            pontosExpiradosData = await pontoDB.getExpiredPoints(
                key,
                administrador.key
            );
        }

        tableData.push({
            key,
            ativo,
            cpf,
            nome,
            sobrenome,
            email,
            endereco,
            ativoStr: ativo ? 'Sim' : 'Não',
            nomeCompleto: `${nome} ${sobrenome}`,
            telefone: endereco.telefone,
            pontosValidos: pontosValidosData ?? false,
            pontosExpirados: pontosExpiradosData ?? false,
            dataCadastro: cadastro ?
                cadastro.toDate().toLocaleString() :
                primeiraPontuacao.data.toDate().toLocaleString(),
            dataUltimaPontuacao: ultimaPontuacao.data.toDate().toLocaleString()
        });
    }

    return tableData;
}

export async function getPontosValidos(tableData) {
    for (let i = 0; i < tableData.length; i++) {
        const { key, pontosValidos } = tableData[i];

        if (!pontosValidos && pontosValidos !== 0) {
            const { administrador } = getConvenio();

            tableData[i].pontosValidos = await pontoDB.getPoints(
                key,
                administrador.key
            );
        }
    }

    return tableData;
}

export async function getPontosExpirados(tableData) {
    for (let i = 0; i < tableData.length; i++) {
        const { key, pontosExpirados } = tableData[i];

        if (!pontosExpirados && pontosExpirados !== 0) {
            const { administrador } = getConvenio();

            tableData[i].pontosExpirados = await pontoDB.getExpiredPoints(
                key,
                administrador.key
            );
        }
    }

    return tableData;
}