import { message } from 'antd';
import { fireBase } from '../../firebase/firebase';
import { authenticate, currentUser } from '../../components/auth/auth-provider';
import { isEmail } from '../../components/funcs/utils';

import usuarioDB from '../../dataManager/dtmUsuario';
import convenioDB from '../../dataManager/dtmConvenio';
import administradorDB from '../../dataManager/dtmAdministrador';
import administradorUsuarioDB from '../../dataManager/dtmAdministradorUsuario';
import vertteStorage from '../../components/localstorage/localstorage';
import API from '../../components/api/api';

const api = new API();


export async function autenticar(email, senha) {
    try {
        let obj;
        let usuario;
        let convenio;
        let administrador;

        if (isEmail(email)) {
            email = email.trim().toLowerCase();

            debugger;
            obj = await fireBase.auth().signInWithEmailAndPassword(email, senha);
            debugger;
            usuario = await usuarioDB.getByUserId(obj.user.uid);
            debugger;

            if (usuario.tipo === 'AdministradorUsuario') {
                const administradorUsuario = await administradorUsuarioDB.getByEmail(email);

                if (administradorUsuario[0].operador) {
                    convenio = await convenioDB.getById(administradorUsuario[0].convenioId);
                    convenio.key = administradorUsuario[0].convenioId;
                }
            } else if (usuario.tipo === 'Administrador') {
                const _administrador = await administradorDB.getByEmail(email);
                administrador = _administrador[0];

                const convenios = await convenioDB.getByOwnerIdAndAdminId(
                    administrador.ownerId,
                    administrador.key
                );

                if (convenios.length > 1) {
                    for (let i = 0; i < convenios.length; i++) {
                        convenios[i].administrador.premiacao = administrador.premiacao;
                    }

                    vertteStorage.set(
                        'loyol-convenios',
                        JSON.stringify(convenios)
                    )
                }

                convenio = convenios[0];
            } else {
                const _convenio = await convenioDB.getByEmail(email);
                convenio = _convenio ? _convenio[0] : undefined;

                if (convenio && convenio.login) {
                    message.error('Usuário não encontrado');
                    fireBase.auth().signOut();
                    return;
                } else if (usuario.tipo === 'Gestor') {
                    message.error('Usuário da conta é gestor (ou seja, dono da conta) e, portanto, não pode fazer login no Fidelidade. Faça login no moub.com.br e verifique o e-mail da rede/convênio.');
                    fireBase.auth().signOut();
                    return;
                } else {
                    message.error('Não foi possível autenticar o usuário corretamente. Código X-error-moub-158');                    
                    fireBase.auth().signOut();
                    return;
                }
                
            }
        } else {
            const login = email.trim();
            const res = await api.authUser(login, senha);

            convenio = res.convenio;
            obj = await fireBase.auth().signInWithCustomToken(res.token);
            usuario = await usuarioDB.getByUserId(obj.user.uid);
        }

        if (!convenio) {
            message.error('Usuário não é um convênio válido');
            fireBase.auth().signOut();
            return;
        }

        if (!administrador) {
            administrador = await administradorDB.getById(convenio.administrador.id);
        }

        convenio.administrador = administrador;
        convenio.administrador.id = administrador.key;
        convenio.administrador.nome = administrador.nomeFantasia;

        // Se não encontrar o usuário, verifico se é um beneficiário.
        // Caso não seja, sei que estou lidando com um Gestor
        if (!usuario && email && isEmail(email)) {
            usuario = {
                uid: obj.user.uid,
                tipo: 'Convenio',
                ownerId: convenio[0].ownerId,
            }

            // Salvo o usuário como gestor
            await usuarioDB.add(usuario);
        }

        if (usuario.tipo !== 'Convenio' && usuario.tipo !== 'Administrador' && usuario.tipo !== 'AdministradorUsuario') {
            message.error('Usuário não é um convênio válido');
            fireBase.auth().signOut();
            return;
        }

        // Salvo em cache
        authenticate(obj.user, convenio);
        return true;
    } catch (error) {
        console.log(error);
        // Handle Errors here.
        if (error.code === 'auth/user-not-found') {
            message.error('Usuário não encontrado', 2.5);
        }
        if (error.code === 'auth/wrong-password') {
            message.error('Senha inválida');
        }

        if (error.code === 'auth/user-disabled') {
            message.error('Usuário desativado pelo administrador');
        }

        return false;
    }
}

export async function redefinePassword(email) {
    return new Promise((resolve) => {
        fireBase.auth().sendPasswordResetEmail(email)
            .then(function () {
                resolve({
                    success: true,
                    message: 'Ok',
                });
            }).catch(function (error) {
                debugger;
                if (error.code === "auth/invalid-email") {
                    error.message = 'Email inválido';
                }
                // An error happened.
                resolve({
                    success: false,
                    message: error.message,
                });
            });
    });
}

export function redirectJustIfUserIsAuthenticated() {
    const user = currentUser();
    if (user) {
        this.props.history.push('/dashboard');
    }
}