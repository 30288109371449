// PAGE TITLES
const TITULO_DEFAULT = 'Regina+';

export const H_01_HORA = 3600000;
export const H_24_HORAS = H_01_HORA * 24;

export const TITULO_PEDIDOS = `${TITULO_DEFAULT} - Pedidos`;


// COLLECTI0NS
export const CL_CONVENIOS = 'convenios';
export const CL_PEDIDOS = 'pedidos';
export const CL_ESTABELECIMENTOS = 'estabelecimentos';


// INDEXES
export const IDX_GESTOR_ID = 'gestorId';
export const IDX_CONVENIO_ID = 'convenio.id';
export const IDX_BOLETO_STATUS = 'boleto.status';
export const IDX_DATA_INICIAL = 'dataInicial';

export const POS_PAGO = 'posPago';
export const PRE_PAGO = 'prePago';


// DIVERSOS
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATA_NAO_MENOR_HOJE = 'A data não pode ser menor ou igual a de hoje';


// DIVERSOS PEDIDOS
export const PEDIDO_JA_FOI_PROCESSADO_CANCELADO = 'Pedido já foi processado e não pode mais ser cancelado.';
export const PEDIDO_CONFIRMAR_CANCELAMENTO = 'Deseja realmente cancelar este pedido? Essa operação não poderá ser desfeita.'
export const PEDIDO_CANCELAMENTO_CONFIRMACAO_TITLE = 'Pedido Cancelado';
export const PEDIDO_CANCELAMENTO_CONFIRMACAO_DESC = 'Seu pedido foi cancelado com sucesso';
export const PEDIDO_CANCELAMENTO_ERRO_TITLE = 'Error';
export const PEDIDO_CANCELAMENTO_ERRO_DESC = 'Houve um problema ao tentar cancelar o boleto. Tente novamente';
export const PEDIDO_VENCIMENTO_ALTERADO_TITLE = 'Sucesso';
export const PEDIDO_VENCIMENTO_ALTERADO_DESC = 'Data de vencimento alterada com sucesso';
export const PEDIDO_VENCIMENTO_ALTERADO_ERRO_TITLE = 'Erro';
export const PEDIDO_VENCIMENTO_ALTERADO_ERRO_DESC = 'Houve uma falha ao tentar alterar a data de vencimento. Tente novamente';
export const PEDIDO_LINHA_DIGITAVEL_COPIADA = 'Linha digitável copiada com sucesso';
export const ESTABELECIMENTO_NOVA_CHAVE_API = 'Essa operação irá substituir a chave atual. Isso fará com que a integração deste estabelecimento para de funcionar até que a nova chave seja distribuída. Deseja mesmo fazer isso?';


// USER TYPES
export const USER_ADMINISTRADOR = 'administrador';
export const USER_CONVENIADO = 'conveniado';


// USER PERMISSIONS
export const USER_CONVENIO_PEDIDOS = 'convenio-pedidos';
export const USER_CONVENIO_PEDIDOS_CADASTRAR = 'convenio-pedidos-cadastrar';
export const USER_CONVENIO_PEDIDOS_CANCELAR = 'convenio-pedidos-cancelar';

export const USER_CONVENIADOR_PEDIDOS = 'conveniador-pedidos';
export const USER_CONVENIADOR_PEDIDOS_EDITAR = 'conveniador-pedidos-editar';
export const USER_CONVENIADOR_PEDIDOS_CANCELAR = 'conveniador-pedidos-cancelar';


// ACCESS DENIED
export const ACCESS_DENIED = 'Você não tem permisão para acessar essa página.';


// ENDEREÇOS DO CACHE
export const FIREBASE_CACHE_CLEAR = '@firebase-cache/clear';
export const FIREBASE_CACHE_UPDATE = '@firebase-cache/update';


// TEMPLATE COMPROVANTE
export const HTML_COMPROVANTE = `<!DOCTYPE html>
<!-- saved from url=(0069)https://storage.googleapis.com/moub-bucket/comprovante-pontuacao.html -->
<html lang="pt-br" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml"
    type="text/css" media="print">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <title></title>

    <meta content="width=device-width, initial-scale=1.0" name="viewport">

    <style>
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap');

        .title {
            font-weight: 700;
            font-size: 24px;
        }

        .subtitle {
            font-weight: 600;
            font-size: 20px;
        }

        .text {
            font-weight: 500;
            font-size: 20px;
        }

        .withBorder {
            border: 2px solid #000000;
            border-radius: 10px;
            padding: 12px;
            margin-bottom: 20px;
        }

        .line {
            display: flex;
        }

        .teste {
            width: 96px;
            text-align: end;
            margin-right: 15px;
        }

        .container {
            width: 410px;
            height: auto;

            padding: 20px;
            border-radius: 10px;

            font-family: 'Inter';
        }

        .containerHeader {
            text-align: center;
            margin-bottom: 20px;
        }

        .containerBeneficiario {
            margin-bottom: 20px;
        }
    </style>
</head>

<body data-new-gr-c-s-check-loaded="14.1063.0" data-gr-ext-installed="" cz-shortcut-listen="true">
    <div class="container">
        <div class="containerHeader">
            <div class="title">
                Moub
            </div>

            <div class="subtitle">
                Comprovante de Pontuação
            </div>

            <div class="subtitle">
                {data} - {hora}
            </div>
        </div>

        <div class="containerBeneficiario">
            <div class="subtitle withBorder">
                Beneficiário
            </div>

            <div class="text">
                <div class="line">
                    <div class="teste">
                        Nome:
                    </div>

                    <div>
                        {beneficiario}
                    </div>
                </div>

                <div class="line">
                    <div class="teste">
                        CPF:
                    </div>

                    <div>
                        {cpf}
                    </div>
                </div>

                <div class="line">
                    <div class="teste">
                        Telefone:
                    </div>

                    <div>
                        {telefone}
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="subtitle withBorder">
                Informações da Compra
            </div>

            <div class="text">
                <div class="line">
                    <div class="teste">
                        Convênio:
                    </div>

                    <div>
                        {convenio}
                    </div>
                </div>

                <div class="line">
                    <div class="teste">
                        Valor:
                    </div>

                    <div>
                        R$ {valor}
                    </div>
                </div>

                <div class="line">
                    <div class="teste">
                        Pontos:
                    </div>

                    <div>
                        {pontos}
                    </div>
                </div>
            </div>
        </div>
    </div>


</body>
<grammarly-desktop-integration data-grammarly-shadow-root="true"></grammarly-desktop-integration>

</html>`;


