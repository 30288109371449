import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router";
import { fireBase } from "./firebase/firebase";
import { logout } from './components/auth/auth-provider';
import ls from 'local-storage'

import Fidelizar from "./pages/fidelizar/Fidelizar";
import Dashboard from "./pages/dashboard/Dashboard"
import Login from "./pages/login/Login";
import Configuracao from "./pages/configuracoes/Configuracao";
import Fidelidades from "./pages/relatorios/fidelidade/Fidelidade";
import Perfil from "./pages/perfil/Perfil";
import Extrato from "./pages/relatorios/extrato/Extrato";
import PainelCliente from "./pages/painel-cliente/PainelCliente";
import Beneficiarios from "./pages/beneficiarios/Beneficiarios";
import EstornarResgates from "./pages/estornar/resgates/EstornarResgates";
import EstornarPontos from "./pages/estornar/pontos/EstornarPontos";

const PUBLIC_ROUTES = {
    login: "/login",
    saldo: "/saldo",
    saldocartao: "/saldo-cartao",
    cliente: "/cliente"
};

class Routes extends React.Component {
    UNSAFE_componentWillMount() {
        fireBase.auth().onAuthStateChanged(user => {
            let { pathname } = this.props.location;
            pathname = pathname.slice(1).replace("-", "");

            if (!user && !PUBLIC_ROUTES[pathname]) {
                logout();
                this.props.history.push("/login");
            }
        });
    }

    render() {
        return (
            <Switch>
                <Route path="/cliente" component={PainelCliente} />

                {/* Telas que precisam estar logado */}

                <PrivateRoute exact path="/dashboard">
                    <Dashboard />
                </PrivateRoute>

                <PrivateRoute exact path="/configuracoes">
                    <Configuracao />
                </PrivateRoute>

                <PrivateRoute exact path="/perfil">
                    <Perfil />
                </PrivateRoute>


                {/* CRUDS */}

                <PrivateRoute exact path="/fidelizar">
                    <Fidelizar />
                </PrivateRoute>


                <PrivateRoute exact path="/convenio-usuarios">
                    <Fidelizar />
                </PrivateRoute>

                <PrivateRoute exact path='/beneficiarios'>
                    <Beneficiarios />
                </PrivateRoute>


                {/*Relatórios */}
                <PrivateRoute exact path="/relatorios/fidelidade">
                    <Fidelidades />
                </PrivateRoute>

                <PrivateRoute exact path="/relatorios/extrato">
                    <Extrato />
                </PrivateRoute>

                {/* Estorno */}
                <PrivateRoute exact path="/estornar/resgates">
                    <EstornarResgates />
                </PrivateRoute>

                <PrivateRoute exact path="/estornar/pontos">
                    <EstornarPontos />
                </PrivateRoute>


                <Route path="/login" component={Login} />
                <Redirect from="*" to="/dashboard" />

            </Switch>
        );
    }
}

export default withRouter(Routes);


function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                const user = ls.get('loyol-user');

                if (user) {
                    return children;
                }

                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            }}
        />
    );
}
