import React from 'react';
import './Configuracao.css';
import PageLayout from '../../components/layout/PageLayout';
import {
    Form,
    Input,
    Tooltip,
    Button,
    Divider,
    Select,
    Icon,
} from 'antd';
import history from '../../history';
import { getOwnerId } from '../../components/auth/auth-provider';
import mensagem from '../../components/messages/message';
import configuracaoDB from '../../dataManager/dtmConfiguracao';


const { Option } = Select;

class Configuracao extends React.Component {

    state = {
        clientId: '',
        clientSecret: '',
        sandbox: 'nao',
        configKey: undefined,
    }

    async componentDidMount() {
        const ownerId = getOwnerId();
        debugger;
        const configuracao = await configuracaoDB.getByOwnerId(ownerId);
        if (configuracao) {
            debugger;
            const sandbox = configuracao[0].boleto.sandbox ? 'sim' : 'nao';
            this.setState({
                configKey: configuracao[0].key,
                clientId: configuracao[0].boleto.clientId,
                clientSecret: configuracao[0].boleto.clientSecret,
                sandbox
            });
        }
    }

    cancelClick() {
        history.push('/dashboard');
        history.go();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (err) {
                return;
            }

            const item = {
                ownerId: getOwnerId(),
                boleto:
                {
                    tipo: 'Gerencianet',
                    clientId: values.clientId,
                    clientSecret: values.clientSecret,
                    sandbox: values.sandbox.toLocaleLowerCase() === 'sim' ? true : false,
                },                
            }

            if (!this.state.configKey) {
                configuracaoDB.add(item);
            }
            else {
                configuracaoDB.update(this.state.configKey, item);
            }

            mensagem.openNotificationWithIcon('success', 'Configurações foram salvas com sucesso');

            setTimeout(() => {
                history.push('/dashboard');
                history.go();
            }, 1500);

        });
    }


    render() {

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <PageLayout selectItem={'configuracoes'}>

                    <label className="lblHeaderConfig">Informe aqui as chaves da GerenciaNet para gerar os boletos de pedido</label>
                    <div className="div-form-profile">
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                            <Form.Item
                                label={
                                    <span>
                                        Client ID&nbsp;
                                    <Tooltip title="Informe o Client ID que se encontra na aba produção na api da Gerencia Net">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                {getFieldDecorator('clientId', {
                                    initialValue: this.state.clientId,
                                    rules: [{ required: true, message: 'Informe o Client ID que se encontra na aba produção na api da Gerencia Net', whitespace: true }],
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item
                                label={
                                    <span>
                                        Client Secret&nbsp;
                                    <Tooltip title="Informe o Client Secret que se encontra na aba produção na api da Gerencia Net">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                {getFieldDecorator('clientSecret', {
                                    initialValue: this.state.clientSecret,
                                    rules: [{ required: true, message: 'Informe o Client Secret que se encontra na aba produção na api da Gerencia Net', whitespace: true }],
                                })(<Input.Password style={{ width: '95%' }} />)}
                            </Form.Item>

                            <Form.Item
                                label={
                                    <span>
                                        Teste?&nbsp;
                                        <Tooltip title="Caso esteja usando as chaves de informação, marque SIM">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                {getFieldDecorator('sandbox', {
                                    initialValue: this.state.sandbox,
                                    rules: [{ required: true, message: 'Caso esteja usando as chaves de informação, marque SIM' }],
                                })(

                                    <Select style={{ width: 120 }}>
                                        <Option value="sim">Sim</Option>
                                        <Option value="nao">Não</Option>
                                    </Select>

                                )}
                            </Form.Item>


                            <Divider></Divider>

                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" loading={this.state.loadingButton}>
                                    Salvar
                                </Button>
                                <Divider type="vertical" />
                                <Button onClick={this.cancelClick}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </PageLayout>
            </div>
        );
    }
}

export default Form.create()(Configuracao);
