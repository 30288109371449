import { getConvenio } from '../../../components/auth/auth-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import pontoDB from '../../../dataManager/dtmPontos';
import API from '../../../components/api/api';
import resgateDB from '../../../dataManager/dtmResgate';


export function getNextColor(color) {
    if (color === "#fff") {
        color = "#eee";
    } else {
        color = "#fff";
    }
    return color;
}

export async function getBeneficiario(search, type) {
    let beneficiario;
    const { ownerId } = getConvenio();

    if (type === 'cpf' || type === 'cnpj') {
        beneficiario = await beneficiarioDB.getByCPFAndOwnerId(search, ownerId);
    } else if (type === 'telefone') {
        beneficiario = await beneficiarioDB.getByPhoneNumberAndOwnerId(search, ownerId);
    } else {
        const words = search.split(' ');
        const exceptions = ['', ' ', '-', '/', 'do', 'da', 'de', '*', 'e'];

        words.forEach((word) => {
            if (!exceptions.includes(word)) {
                word = word.toLocaleLowerCase();
                word = word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }
        });

        beneficiario = await beneficiarioDB.getByPalavraChaveAndOwnerId(words, ownerId);

        if (beneficiario.length > 1) {
            beneficiario.sort((a, b) => a.nome.localeCompare(b.nome));
            beneficiario.sort((a, b) => a.sobrenome.localeCompare(b.sobrenome));
        }
    }

    return beneficiario;
}

export async function getDataByCPF(beneficiarioId, exibirPontosResgatados, dataIni, dataFim) {
    let inicio = undefined;
    let fim = undefined;

    if (dataIni && dataFim) {
        inicio = dataIni.startOf('day').toDate();
        fim = dataFim.endOf('day').toDate();
    }

    const convenio = getConvenio();
    const pontos = await pontoDB.getByParticipante(
        beneficiarioId,
        convenio.key,
        exibirPontosResgatados,
        inicio,
        fim);

    let color = '';

    pontos.forEach(ponto => {
        color = getNextColor(color);
        ponto.color = color;
        ponto.data = ponto.data.toDate().toLocaleString();
        ponto.estornou = ponto.estornou ? 'Sim' : 'Não';
        ponto.dataEstorno = ponto.dataEstorno ? ponto.dataEstorno.toDate().toLocaleString() : '';
        ponto.expiraEm = ponto.expiraEm ? ponto.expiraEm.toDate().toLocaleDateString() : 'Não Expira';
        ponto.resgatou = ponto.resgatou ? 'Sim' : 'Não';
        ponto.valor = (ponto.valueInCents / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    });

    return pontos;
}

export async function getData(exibirPontosResgatados, dataIni, dataFim) {
    let inicio = undefined;
    let fim = undefined;

    if (dataIni && dataFim) {
        inicio = dataIni.startOf('day').toDate();
        fim = dataFim.endOf('day').toDate();
    }

    const convenio = getConvenio();
    const pontos = await pontoDB.getByConvenio(
        convenio.key,
        exibirPontosResgatados,
        inicio,
        fim);

    let color = '';

    pontos.forEach(ponto => {
        color = getNextColor(color);
        ponto.color = color;
        ponto.data = ponto.data.toDate().toLocaleString();
        ponto.expiraEm = ponto.expiraEm ? ponto.expiraEm.toDate().toLocaleDateString() : 'Não Expira';
        ponto.resgatou = ponto.resgatou ? 'Sim' : 'Não';
        ponto.valor = (ponto.valueInCents / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    });

    return pontos;
}

export async function getResgatesByCPF(beneficiarioId, dataIni, dataFim) {

    let inicio = undefined;
    let fim = undefined;

    if (dataIni && dataFim) {
        inicio = dataIni.startOf('day').toDate();
        fim = dataFim.endOf('day').toDate();
    }

    const convenio = getConvenio();

    const resgates = await resgateDB.getByParticipante(
        beneficiarioId,
        convenio.key,
        inicio,
        fim);

    let color = '';

    let pontosBaixados = 0;
    let counter = 0;
    resgates.forEach(resgate => {
        color = getNextColor(color);
        counter += 1;
        resgate.color = color;
        resgate.key = counter;
        resgate.data = resgate.data.toDate().toLocaleString();
        resgate.estornou = resgate.estornou ? 'Sim' : 'Não';
        resgate.dataEstorno = resgate.dataEstorno ? resgate.dataEstorno.toDate().toLocaleString() : '';

        pontosBaixados += resgate.pontos;
    });

    color = getNextColor(color);
    counter += 1;
    const resgate = {
        key: counter,
        color,
        data: 'Total',
        pontos: pontosBaixados,
        estornou: '-',
        resgateId: '--'
    }
    resgates.push(resgate);
    return resgates;
}

export async function getResgates(dataIni, dataFim) {
    let inicio = undefined;
    let fim = undefined;

    if (dataIni && dataFim) {
        inicio = dataIni.startOf('day').toDate();
        fim = dataFim.endOf('day').toDate();
    }

    const convenio = getConvenio();

    const resgates = await resgateDB.getByConvenio(
        convenio.key,
        inicio,
        fim);

    let color = '';

    let pontosBaixados = 0;
    let counter = 0;

    resgates.forEach(resgate => {
        color = getNextColor(color);
        counter += 1;
        resgate.color = color;
        resgate.key = counter;
        resgate.data = resgate.data.toDate().toLocaleString();
        resgate.estornou = resgate.estornou ? 'Sim' : 'Não';

        pontosBaixados += resgate.pontos;
    });

    color = getNextColor(color);
    counter += 1;
    const resgate = {
        key: counter,
        color,
        data: 'Total',
        pontos: pontosBaixados,
        estornou: '-',
        resgateId: '--'
    }

    resgates.push(resgate);

    return resgates;
}

export async function getBalance(userCPForCelular, findByCelular) {

    const convenio = getConvenio();

    const api = new API();
    let get = undefined;

    try {
        get = await api.getToken(convenio.key);
    } catch (err) {
        console.log(err);
        return undefined;
    }

    if (get && get.token) {

        return new Promise((resolve, reject) => {

            api.getPoints(userCPForCelular, findByCelular, get.token)
                .then(res => {

                    let color = '';
                    const awards = res.awards.availableItems;
                    let counter = 0;

                    awards.forEach(award => {
                        counter += 1;
                        award.key = counter;
                        color = getNextColor(color);
                        award.color = color;
                    });

                    resolve({
                        canRescue: res.balance.canRescue,
                        score: Math.trunc(res.balance.score),
                        awards,
                    });
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });

        });
    }
}


